import React from 'react'

const ProductPerformance = () => {
  return (
    <div className='text-center text-2xl font-bold flex w-full items-center justify-center'>
      ProductPerformance Page
    </div>
  )
}

export default ProductPerformance
