import { BsChevronDown, BsChevronUp } from "react-icons/bs"; 
import { CgProfile } from "react-icons/cg"; 
import { RiUserSettingsLine, RiDashboard3Fill } from "react-icons/ri"; 
import { AiOutlineHome } from "react-icons/ai"; 
import { FaUsers, FaBox, FaFileInvoice, FaMoneyBillWave, FaIndustry, FaBell, FaCalendarAlt } from "react-icons/fa"; 
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Updated sidebarOptions with the new structure
const sidebarOptions = [
  {
    name: "Dashboard",
    icon: <RiDashboard3Fill />,
    path: "/dashboard",
    subOptions: [
      { name: "Dashboard", path: "/dashboard" },
      { name: "Reporting", path: "/dashboard/reporting" },
    ],
  },
  {
    name: "Users",
    icon: <FaUsers />,
    path: "/users",
  },
  {
    name: "Item/Subitem",
    icon: <FaBox />,
    path: "/items&subitems",
    subOptions: [
      { name: "Item/Subitem", path: "/items&subitems" },
      { name: "Item Categories", path: "/items/categories" },
      { name: "Items", path: "/items/items" },
      { name: "Subitems", path: "/items/subitems" },
      { name: "Assets", path: "/items/assets" },
    ],
  },
  {
    name: "Purchase",
    icon: <FaFileInvoice />,
    path: "/purchase",
    subOptions: [
      { name: "Purchase", path: "/purchase" },
      { name: "Purchase Orders", path: "/purchases/purchase-order" },
    ],
  },
  {
    name: "Payments",
    icon: <FaMoneyBillWave />,
    path: "/payment",
    subOptions: [
      { name: "Payments", path: "/payment" },
      { name: "Other Income", path: "/payments/income-management" },
    ],
  },
  {
    name: "Manufacturing",
    icon: <FaIndustry />,
    path: "/manufacturing",
    subOptions: [
      { name: "Manufacturing", path: "/manufacturing" },
      { name: "Add Product Stock", path: "/manufacturing/add-product" },
    ],
  },
  {
    name: "Sales/Service",
    icon: <FaBell />,
    
    path: "/sales&service",
    subOptions: [
      { name: "Sales/Service", path: "/sales&service" },
      { name: "Dealer Order Request", path: "/Sales-Service/dealer-ord" },
      { name: "Warranty Management", path: "/Sales-Service/warraty-Manage" },
      { name: "Product Service", path: "/Sales-Service/prod-service" },
    ],
  },
  {
    name: "Attendance",
    icon: <FaCalendarAlt />,
    path: "/attendance",
    subOptions: [
      { name: "Attendance", path: "/attendance" },
      { name: "Employee Wise Report", path: "/attendance/employee-report" },
    ],
  },
];

const VerticalSidebar = () => {
  const [activeOption, setActiveOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState({}); // State to track which dropdowns are open
  const navigate = useNavigate();

  const toggleDropdown = (optionName) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [optionName]: !prevState[optionName], // Toggle the dropdown state for the clicked option
    }));
  };

  const handleNavigation = (path, option) => {
    navigate(path);
    setActiveOption(option.name); // Set the clicked option as active
  };

  return (
    <div className="fixed bg-[#f9f9fb] top-[4rem] left-0 h-full w-[250px] border-r border-gray-300 z-30 flex flex-col items-start py-4 px-4">
      {sidebarOptions.map((option, index) => (
        <div
          key={index}
          className={`relative cursor-pointer flex flex-col items-start justify-center gap-2 my-2 w-full ${
            activeOption === option.name ? 'border-l-4 border-blue-500 text-blue-500' : ''
          }`}
        >
          <div 
            className="flex items-center justify-between w-full hover:text-blue-500 transition-colors duration-200"
            onClick={() => {
              if (option.subOptions && option.subOptions.length > 0) {
                toggleDropdown(option.name); // Toggle dropdown if it has sub-options
              } else {
                handleNavigation(option.path, option); // Navigate to path if no sub-options
              }
            }}
          >
            <div className="flex items-center">
              <span className={`text-2xl mr-2 ${activeOption === option.name ? 'text-blue-500' : 'text-gray-800'}`}>
                {option.icon}
              </span>
              <div className={`text-sm flex gap-1 items-center ${activeOption === option.name ? 'text-blue-500' : 'text-gray-800'}`}>
                {option.name}
              </div>
            </div>
            {option.subOptions && option.subOptions.length > 0 && (
              <span className={`transform ${dropdownOpen[option.name] ? 'rotate-180' : 'rotate-0'} transition-transform`}>
                {dropdownOpen[option.name] ? <BsChevronUp /> : <BsChevronDown />}
              </span>
            )}
          </div>

          {/* Dropdown with sub-options */}
          {dropdownOpen[option.name] && option.subOptions && (
            <div className="mt-2 ml-6 flex flex-col gap-1">
              {option.subOptions.map((subOption, subIndex) => (
                <div
                  key={subIndex}
                  className="text-sm text-gray-700 hover:bg-gray-100 px-2 py-1 rounded cursor-pointer transition-colors duration-200"
                  onClick={() => handleNavigation(subOption.path, option)} // Navigate on sub-option click
                >
                  {subOption.name}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default VerticalSidebar;
