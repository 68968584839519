import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { HiOutlineFilter, HiX, HiDotsVertical } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import ConfirmationModal from "../Modal/confirmationmodal/ConfirmationModal";
import { postReq } from "../../apis/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EditUser from "../Modal/user/EditUser";

const CustomDatatable = ({ columns, data, fetchUsers }) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState("User Name"); // Default to a valid column
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [globalSearchText, setGlobalSearchText] = useState("");
  const navigate = useNavigate();
  const [openEdit, SetOpenEdit] = useState(false);
  const [rowData, setData] = useState({});

  // Define allowed filter columns based on the columns you provided
  const allowedFilterColumns = [
    { name: "User Name", selector: "user_name" },
    { name: "User Type", selector: "employee_type" },
    // { name: "GSTIN Number", selector: "gstin_number" },
    { name: "Status", selector: "status" },
  ];

  // Helper to access row data based on the column selector
  const getColumnValue = (row, selectorFn) => {
    if (typeof selectorFn === "function") {
      return selectorFn(row);
    }
    return row[selectorFn] || "";
  };

  // Filter data based on selected column
  const filteredData = data.filter((item) => {
    const column = allowedFilterColumns.find(
      (col) => col.name === selectedColumn
    );
    const valueToFilter = getColumnValue(item, column.selector)
      .toString()
      .toLowerCase();
    return valueToFilter.includes(filterText.toLowerCase());
  });

  const globallyFilteredData = filteredData.filter((item) => {
    const userName = item.user_name?.toLowerCase() || "";
    return userName.includes(globalSearchText.toLowerCase());
  });

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };


  const handleStatus = async (row, newStatus) => {
    try {
      setConfirmationModal(null);
      const response = await postReq(
        { status: newStatus, id: row.id },
        "/updateUserStatus"
      );
      if (response.data.status === 200) {
        toast.success(`Status changed to ${newStatus} for ${row.user_name}`);
        setTimeout(() => {
          fetchUsers();
        }, 1000);
      }
    } catch (error) {
      toast.error("Failed to update status");
    }
  };

  const actionColumn = {
    name: "Actions",
    cell: (row) => (
      <Menu as="div" className="relative">
        <Menu.Button className="sticky top-0">
          <HiDotsVertical className="w-6 h-6 text-gray-500 cursor-pointer" />
        </Menu.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items className="absolute top-[-4vw] right-5 w-48 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-[1vw]`}
                  onClick={() => navigate(`/users/user-detail/${row.id}`)}
                >
                  View
                </button>
              )}
            </Menu.Item>
            {/* <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-[1vw]`}
                  onClick={() => editUserModal(row)}
                >
                  Edit
                </button>
              )}
            </Menu.Item> */}

            <Menu.Item>
              {({ active }) => {
                const newStatus =
                  row.status === "Enable" ? "Disable" : "Enable";
                return (
                  <button
                    className={`${
                      active ? "bg-blue-500 text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-4 py-2 text-[1vw]`}
                    onClick={() =>
                      setConfirmationModal({
                        text1: "Are you sure?",
                        text2: `You want to change status to ${newStatus}?`,
                        btn1Text: newStatus,
                        btn2Text: "Cancel",
                        btn1Handler: () => handleStatus(row, newStatus),
                        btn2Handler: () => setConfirmationModal(null),
                      })
                    }
                  >
                    Change Status
                  </button>
                );
              }}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    right: true,
    style: {
      position: "sticky",
      right: "0px",
      zIndex: 1,
    },
  };

  const enhancedColumns = [...columns, actionColumn];

  const subHeaderComponent = (
    <div className="flex justify-between bg-white text-[#666666] items-center my-8px-vw relative">
      <div className="relative">
        <input
          className="border p-8px-vw pr-40px-vw bg-white rounded-[8px] focus:outline-none placeholder:text-16px-vw mr-16px-vw"
          type="text"
          placeholder="Search users..."
          value={globalSearchText}
          onChange={(e) => setGlobalSearchText(e.target.value)}
        />

        {/* Conditionally render the cross icon inside the search input field */}
        {globalSearchText && (
          <HiX
            className="absolute right-20px-vw top-1/2 bg-transparent transform -translate-y-1/2 cursor-pointer h-6 w-6 text-gray-500"
            onClick={() => setGlobalSearchText("")}
          />
        )}
      </div>
      
      <select
        className="border bg-white focus:outline-none p-12px-vw rounded-[8px] text-16px-vw mr-8px-vw"
        value={selectedColumn}
        onChange={(e) => {
          setSelectedColumn(e.target.value);
          setFilterText(""); // Clear filter text when changing column
        }}
      >
        {allowedFilterColumns.map((col) => (
          <option key={col.selector} value={col.name}>
            {col.name}
          </option>
        ))}
      </select>
     

      <div className="relative w-full">
        <input
          className="border bg-white p-8px-vw pr-40px-vw focus:outline-none rounded-[8px] placeholder:text-16px-vw w-full"
          type="text"
          placeholder={`Filter by ${selectedColumn}`}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />

        {/* Conditionally render the cross icon inside the input field */}
        {filterText && (
          <HiX
            className="absolute right-2 bg-transparent top-1/2 transform -translate-y-1/2 cursor-pointer h-6 w-6 text-gray-500"
            onClick={handleClear}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="font-poppins">
      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
      {/* {
        <EditUser
          open={openEdit}
          handleClose={closeUserModal}
          data={rowData}
          fetchUsers={fetchUsers}
        />
      } */}
      <div className="overflow-hidden  !bg-white rounded-[0.42vw]">
        <DataTable
          columns={enhancedColumns}
          data={globallyFilteredData}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
          customStyles={{
            rows: {
              style: {
                minHeight: "60px",
                fontSize: "0.9vw",
              },
            },
            headCells: {
              style: {
                fontWeight: 600,
                textAlign: "center",
                whiteSpace: "normal",
                fontSize: "1vw",
                color: "#10131e",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default CustomDatatable;
