import React, { useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import Button from "../../components/Button";
import { Outlet } from "react-router-dom";
import AddCategoryModal from "../../components/Modal/categorymodal/AddCategoryModal";

const Index = () => {
 
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Index; // Make sure to also update the export statement.
