import React from 'react';

const BreadCrumbs = ({ title, customClasses }) => {
  // Split the title into an array based on the separator
  const breadcrumbs = title.split(" / ");

  return (
    <div className={`font-poppins bg-white cursor-pointer md:rounded-[0.42vw] rounded-[1vw] md:py-10px-vw   text-[#828690] text-[3.5vw] md:text-20px-vw ${customClasses}`}>
      {breadcrumbs.map((crumb, index) => {
        const isActive = index === breadcrumbs.length - 1; 
        return (
          <span key={index} className={isActive ? "text-[#0074ff] bg-white" : "text-[#828690] bg-white"}>
            {crumb}
            {index < breadcrumbs.length - 1 && " / "} 
          </span>
        );
      })}
    </div>
  );
};

export default BreadCrumbs;
