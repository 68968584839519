import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/common/BreadCrumbs' 
import Button from "../../components/Button";
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/Loading';
import { toast } from 'react-toastify';
import { getReq } from '../../apis/api';
import AllProductTable from '../../components/DataTable/AllProductTable';

const Management = () => {  
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
    const navigate=useNavigate(); 




    const fetchProductData = async () => {
      try {
        setIsLoading(true);
        const response = await getReq("/products");
  
        if (response.status === 200) {
          setCategories(response.data);
        }
      } catch (error) {
        console.log("Error", error);
        toast.error("Error in Fetching Product Categories");
      } finally {
        setIsLoading(false);
      }
    };
  
    useEffect(() => {
      fetchProductData();
    }, []); 


    
    const columns = [
      {
        name: "#",
        selector: (row, index) => index + 1,
        sortable: false,
        width: "100px",
        center: true,
      },
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
        width: "100px",
      }, 
      {
        name: "Category",
        selector: (row) => row.product_category || "N/A",
        sortable: true,
        wrap: true,
      },
     
      {
        name: "Name",
        selector: (row) => row.product_name,
        sortable: true,
      },
  
      // {
      //   name: "Description",
      //   selector: (row) => row.description || "N/A",
      //   sortable: true,
      // }, 
       {
        name: "HSN Code",
        selector: (row) => row.hsn_code || "N/A",
        sortable: true,
        wrap: true,
      },  
      {
        name: "Product SKU",
        selector: (row) => row.product_sku || "N/A",
        sortable: true,
        wrap: true,
      },  
      {
        name: "Weight",
        selector: (row) => row.weight || "N/A",
        sortable: true,
        wrap: true,
      },  
      {
        name: "Stock",
        selector: (row) => row.stock || "N/A",
        sortable: true,
        wrap: true,
      },  
     
     
  
      {
        name: "Status",
        selector: (row) => row.status || "N/A",
        cell: (row) => (
          <div
            className={`px-4 py-1 rounded-[0.42vw]  ${
              row.status === "Enable"
                ? "bg-[#d8fff8] text-[#01bd9b]"
                : row.status === "Disable"
                ? "bg-[#faebe9] text-[#D0412E]"
                : "bg-gray-200 text-gray-600"
            }`}
          >
            {row.status || "N/A"}
          </div>
        ),
        sortable: true,
        wrap: true,
      }, 

      {
        name: "Grand Total",
        selector: (row) => row.grand_total || "N/A",
        sortable: true,
        wrap: true,
      }, 
    ];




  return (
    <div> 
     <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Manufacturing / Products"} />
        <Button title="Create" func={()=>navigate("/manufacturing/create-product")} />
      </div> 

      <main>
        {isLoading ? (
          <Loader />
        ) : (
          <AllProductTable columns={columns} data={categories}   onItemAdded={fetchProductData}/>
        )}
      </main>
      
    </div>
  )
}

export default Management
