import React from 'react'
import { Outlet } from 'react-router-dom'
import BreadCrum from '../../components/BreadCrum/BreadCrum'
import BreadCrumbs from '../../components/common/BreadCrumbs'

export const Access = () => {
  return (
    <div>
      <div>
     
      <div className="py-20px-vw">
        <BreadCrumbs title={"Home / Access Management"} />
        </div>
    <div className='mt-[1vw]'>
    <Outlet/>
    </div>
   </div>
    </div>
  )
}

export default Access
