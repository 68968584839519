import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ItemIcon from "@mui/icons-material/Category";
import Button from "../../Button";
import { toast } from "react-toastify";
import { postReq } from "../../../apis/api";

const AddCategory = ({ open, handleClose, title }) => {
  const [name, setName] = React.useState("");

  const handleInputChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.trim()) {
      try {
        const data = { name };
        const response = await postReq(data,"/product-categories"); 
        console.log("reponse",response)
        if (response.status === 201) {
          toast.success("Product category created successfully!");
          setName(""); // Reset the field after submission
          handleClose(); // Close the modal
        }
      } catch (error) {
        toast.error("Error creating item category. Please try again."); // Show error toast
        console.error("API error:", error);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm" // Adjust max width as needed (sm, md, lg, xl)
      sx={{
        "& .MuiDialogContent-root": {
          padding: "24px !important", // Increase padding in the dialog content
        },
      }}
    >
      <DialogTitle
        className="border-b border-gray-300"
        sx={{ position: "relative" }}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="md:mt-20px-vw">
        <TextField
          id="name"
          label="Add Category"
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <ItemIcon className="mr-2" style={{ fontSize: "1vw" }} />
            ), // Icon at the start with font size
          }}
          InputLabelProps={{
            sx: {
              fontSize: "1vw", // Font size for the label
            },
          }}
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "1vw", // Font size for the input text
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "1vw", // Font size for the placeholder
            },
          }}
          placeholder="Enter Product Category" // Adding a placeholder
        />
      </DialogContent>

      <DialogActions className=" md:mr-14px-vw md:mb-10px-vw ">
        <Button
          func={handleClose}
          title={"Back"}
          customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
        />
        <Button func={handleSubmit} title={"Save"} />
      </DialogActions>
    </Dialog>
  );
};

export default AddCategory;
