import { FaUserAlt, FaUserCircle } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { BiUniversalAccess } from "react-icons/bi";
import { MdNotificationAdd } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import React, { useState, useEffect, useRef } from "react";
import HorizontalSidebar from "./Horizontal";
import VerticalSidebar from "./Vertical";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../Modal/confirmationmodal/ConfirmationModal";
import { logoutSuccess } from "../../slices/authSlice";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { logoutReq } from "../../apis/api";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchModalRef = useRef(null);

  const userCookie = Cookies.get("user");
  const user = userCookie ? JSON.parse(userCookie) : null;

  const roleId = user?.role_id;
  const username = user?.username;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => { 
    const token = Cookies.get("token");
    try {
      const response = await logoutReq(token);

      if (response.status === 200) {
        setConfirmationModal(null);
        await dispatch(logoutSuccess());
        toast.success("You have successfully logged out!"); 
        Cookies.remove("token");
        Cookies.remove("user");
        navigate("/login");
      } else {
        toast.error(`Logout failed: ${response.message}`);
      }
    } catch (error) {
      // Handle unexpected errors
      toast.error(
        `Error during logout: ${
          error.message || "An unexpected error occurred."
        }`
      );
    }
  };

  const getGreeting = () => {
    const currentHour = new Date().getHours(); // Get the current hour
    if (currentHour < 12) {
      return "Good Morning!";
    } else if (currentHour < 16) {
      return "Good Afternoon!";
    } else if (currentHour < 18) {
      return "Good Evening!";
    } else {
      return "Good Night!";
    }
  };
  const handleClickOutside = (event) => {
    if (
      searchModalRef.current &&
      !searchModalRef.current.contains(event.target)
    ) {
      setIsSearchModalOpen(false);
    }
  };

  useEffect(() => {
    if (isSearchModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchModalOpen]);

  const openprofile = () => {
    setIsModalOpen(true);
  };
  return (
    <div className="flex flex-col relative">
      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
      {/* Navbar */}
      <div className="fixed top-0 z-30 border-[1px] bg-[#f9f9fb] border-b-gray-300 flex flex-row items-center justify-between px-[1vw] h-[70px] w-full">
        <div className="md:w-[8vw] w-[20vw] border-r-[2px] md:border-r-[1px] h-full  flex items-center justify-center">
          <Link to="/">
            <div className="w-[15vw]  md:w-[4vw]">
              <img
                src={logo}
                alt="logo"
                className="w-full h-auto object-cover "
              />
            </div>
          </Link>
        </div>
        <div className="w-[85vw] flex px-[1vw] justify-between">
          <div className="md:block hidden">
            <HorizontalSidebar />
          </div>
          <div className="flex items-center justify-center">
            <div className="md:hidden block">
              <button
                onClick={toggleMenu}
                className="flex flex-col justify-center items-center w-8 h-8 space-y-[1.5vw] focus:outline-none"
              >
                <div
                  className={`${
                    isOpen ? "hidden" : "block"
                  } w-6 h-[.4vw] bg-black transition-all duration-300`}
                ></div>
                <div
                  className={`${
                    isOpen ? "hidden" : "block"
                  } w-6 h-[.4vw] bg-black transition-all duration-300`}
                ></div>
                <div
                  className={`${
                    isOpen ? "hidden" : "block"
                  } w-6 h-[.4vw] bg-black transition-all duration-300`}
                ></div>
                <div
                  className={`${
                    isOpen ? "block" : "hidden"
                  } w-6 h-6 border-t-2 border-r-2 scale-75 border-black transform rotate-45 transition-all duration-300`}
                ></div>
              </button>
            </div>
          </div>
          <div className="flex gap-x-2 items-center justify-center">
            {/* Search Bar */}
            <div className="flex items-center gap-x-[0.5vw]">
              <div className="relative w-10 transition-all duration-300 ease-in-out md:w-full max-w-sm">
                <div
                  className="cursor-pointer border-[1px] border-solid border-[#eef0f7] bg-white  px-[1vw] py-[1.8vw] md:p-[0.5vw] rounded-[8px] flex items-center justify-center"
                  onClick={() => {
                    setIsSearchModalOpen(true); // Open the search modal
                  }}
                >
                  <FaSearch className="text-gray-500 md:text-[1.3vw] text-[6vw]" />
                </div>

                {/* Search Modal */}
                {isSearchModalOpen && (
                  <div
                    ref={searchModalRef}
                    className="absolute top-[6vh] flex items-center justify-between md:w-[20vw] w-[65vw] right-[1vw] z-20 border border-gray-300 rounded-md shadow-lg mt-1 p-2"
                  >
                    <input
                      type="text"
                      placeholder="Type your search..."
                      className="md:w-[12vw] w-[45vw] md:py-[0.5vw] py-[2vw] border border-gray-300 rounded-md focus:outline-none"
                    />
                    <button className="text-white px-[1vw] py-[2vw] md:py-1 rounded-md">
                      Search
                    </button>
                  </div>
                )}
              </div>

              <div className="border-[1px] bg-white border-solid border-[#eef0f7] relative rounded-[8px]">
                <div className="w-[1.1vw] h-[1.1vw] flex items-center justify-center  rounded-full bg-[#0074ff] absolute overflow-hidden top-[-0.4vw] right-[-0.4vw]">
                  <span className="text-white text-xs">9</span>
                </div>

                <div className="md:py-[0.5vw] py-[2vw] px-2 flex items-center justify-center">
                  <FaBell
                    className="md:text-[1.3vw] text-[6vw] text-gray-500 cursor-pointer"
                    onClick={() => console.log("Notification icon clicked")} // Example logging
                  />
                </div>
              </div>

              <div className="border-[1px] relative bg-white border-solid border-[#eef0f7] rounded-[8px]">
                <div className="w-[1.1vw] h-[1.1vw] flex items-center justify-center  rounded-full bg-black absolute overflow-hidden top-[-0.4vw] right-[-0.4vw]">
                  <span className="text-white text-xs">4</span>
                </div>
                <div className="md:py-[0.5vw] py-[2vw] px-2 flex items-center justify-center">
                  <FaCheck
                    className="md:text-[1.3vw] text-[6vw] text-gray-500 cursor-pointer"
                    onClick={() => console.log("Check icon clicked")} // Example logging
                  />
                </div>
              </div>

              {/* Profile Button with Modal */}
              <div
                className="relative cursor-pointer rounded-[8px] bg-white border-[1px] border-solid border-[#eef0f7]"
                onMouseEnter={() => setIsModalOpen(true)}
                onMouseLeave={() => setIsModalOpen(false)}
              >
                <div className="md:py-[0.5vw] py-[2vw] px-2 flex items-center justify-center  relative cursor-pointer">
                  {/* Replace profile icon image with a new icon */}
                  <FaUserCircle className="text-gray-500 md:text-[1.3vw] text-[6vw]" />

                  {/* Profile Modal Section */}
                  {isModalOpen && (
                    <div className="absolute top-[110%] z-50 right-0 bg-gradient-to-r from-blue-400 to-blue-600 p-6 w-[320px] rounded-[0.42vw] text-white transform transition-all duration-300 ease-out">
                      <div className="flex items-center mb-4 bg-transparent space-x-4">
                        <CgProfile
                          className="text-white "
                        />
                        <div className="flex flex-col bg-transparent">
                          <span className="text-lg font-semibold ">
                            Hi {username || "Guest"},
                          </span>
                          <span className="text-sm font-medium ">
                            {getGreeting()}
                          </span>
                        </div>
                      </div>

                      <div className="flex flex-col font-poppins gap-3 ">
                        {/* Profile Button */}
                        <button
                          className="text-left flex items-center hover:bg-blue-500 p-2 rounded transition-all duration-200"
                          onClick={() =>
                            navigate(`/profile/${roleId || "defaultRole"}`)
                          }
                        >
                          <CgProfile size={20} className="mr-3 " />
                          Profile
                        </button>

                        {/* Settings Button */}
                        <button
                          className="text-left flex items-center hover:bg-blue-500 p-2 rounded transition-all duration-200"
                          onClick={() =>
                            navigate(`/settings/${roleId || "defaultRole"}`)
                          }
                        >
                          <CiSettings size={20} className="mr-3 " />
                          Settings
                        </button>

                        {/* Send Notifications Button */}
                        <button
                          className="text-left flex items-center hover:bg-blue-500 p-2 rounded transition-all duration-200"
                          onClick={() =>
                            navigate(
                              `/notifications/${roleId || "defaultRole"}`
                            )
                          }
                        >
                          <MdNotificationAdd size={20} className="mr-3 " />
                          Send Notifications
                        </button>

                        {/* Access Management Button */}
                        <button
                          className="text-left flex items-center hover:bg-blue-500 p-2 rounded transition-all duration-200"
                          onClick={() => navigate("/access-management")}
                        >
                          <BiUniversalAccess
                            size={20}
                            className="mr-3 bg-transparent"
                          />
                          Access Management
                        </button>

                        {/* Logout Button */}
                        <button
                          className="text-left flex items-center hover:bg-blue-500 p-2 rounded transition-all duration-200"
                          onClick={() =>
                            setConfirmationModal({
                              text1: "Are you sure?",
                              text2: "You want to log out?",
                              btn1Text: "Logout",
                              btn2Text: "Cancel",
                              btn1Handler: async () => {
                                await handleLogout();
                              },
                              btn2Handler: () => setConfirmationModal(null),
                            })
                          }
                        >
                          <AiOutlineLogout
                            size={20}
                            className="mr-3 bg-transparent"
                          />
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sidebar */}
      <div className="md:block hidden ">
        <HorizontalSidebar />
      </div>
      <div
        className={`fixed top-0 left-0 h-full w-[250px] bg-gray-800 text-white transition-transform duration-500 ease-in-out transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <VerticalSidebar />
      </div>
    </div>
  );
};

export default Header;
