export default function IconBtn({
  text,
  onclick,
  children,
  disabled,
  outline = false,
  customClasses = "",
  type = "button",
}) {
  return (
    <button
      disabled={disabled}
      onClick={onclick}
      className={`flex items-center transition-all duration-200 ease-in-out ${
        outline
          ? "border  text-gray-800 bg-transparent  "
          : " rounded-[0.25vw] text-white "
      } cursor-pointer gap-x-2 rounded-md py-2 px-4 font-semibold   focus:outline-none  ${
        disabled ? "opacity-50 cursor-not-allowed" : ""
      } ${customClasses}`}
      type={type}
    >
      {children ? (
        <>
          <span>{text}</span>
          {children}
        </>
      ) : (
        text
      )}
    </button>
  );
}
