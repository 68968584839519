import IconBtn from "./IconBtn";

export default function ConfirmationModal({ modalData }) {
  return (
    <div className="fixed inset-0 z-[1000] grid place-items-center overflow-auto bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="w-11/12 max-w-[400px] rounded-lg border border-gray-300 bg-white  p-8">
        <p className="text-xl font-bold text-gray-800 bg-white">{modalData?.text1}</p>
        <p className="mt-2 mb-4 text-gray-600 bg-white">{modalData?.text2}</p>
        <div className="flex items-center justify-between">
          <IconBtn
            onclick={modalData?.btn1Handler}
            text={modalData?.btn1Text}
            outline={true}
            customClasses="flex-1  mr-2 !bg-[#0074ff] text-white hover:bg-blue-700" // Add spacing between buttons
          />
          <IconBtn
            onclick={modalData?.btn2Handler}
            text={modalData?.btn2Text}
            customClasses="flex-1 !bg-[#d0412e33] !text-[#D0412E] hover:bg-[#D0412E] hover:text-white" // Equal width for both buttons
          />
        </div>
      </div>
    </div>
  );
}
