import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const SessionTimeout = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    toast.info("Redirecting to login...");
    navigate("/login");
  };

  return (
    <div className="flex items-center justify-center h-screen bg-transparent">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-sm w-full text-center">
        <h2 className="text-2xl font-bold text-gray-800">Session Expired</h2>
        <p className="mt-4 text-gray-600">Please log in again using the app</p>
        <div className="mt-6">
          {/* <img
            src="/path/to/your/image.png" // Use a relevant illustration
            alt="Session Expired"
            className="mx-auto mb-4 w-32 h-32 object-contain"
          /> */}
        </div>
        <button
          onClick={handleLoginRedirect}
          className="mt-4 px-6 py-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition duration-200"
        >
          Log In
        </button>
      </div>
    </div>
  );
};

export default SessionTimeout;
