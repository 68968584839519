import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { HiDotsVertical } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

const PurchaseOrderTable = ({ data, onStatusChange, onPayment, onCancel }) => {
  const [filterText, setFilterText] = useState("");
  console.log(data, "table dataaa");
  const navigate = useNavigate();
  const columns = [
    { name: "Purchase Order Number", selector: (row) => row.purchase_order_number, sortable: true },
    { name: "Supplier ID", selector: (row) => row.supplier_id, sortable: true },
    { name: "Order Date", selector: (row) => row.order_date, sortable: true },
    { name: "Expected Delivery Date", selector: (row) => row.expected_delivery_date, sortable: true },
    { name: "Advance Payment", selector: (row) => row.advance_payment, sortable: true },
    { 
        name: "Status", 
        selector: (row) => row.status === "0" ? "Due" : "Received", 
        sortable: true 
      },
    {
      name: "Actions",
      cell: (row) => (
        <Menu as="div" className="relative">
          <Menu.Button>
            <HiDotsVertical className="w-6 h-6 text-gray-500 cursor-pointer" />
          </Menu.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="absolute right-0 w-48 bg-white shadow-lg rounded-md">
              <Menu.Item>
                {({ active }) => (
                    <button 
                    className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} w-full px-4 py-2`}
                    onClick={() => navigate(`/purchases/edit-purchase-order/${row.purchase_order_number}`)}>
                    View/Edit
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} w-full px-4 py-2`}
                    onClick={() => onStatusChange(row)}
                  >
                    Status Change
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} w-full px-4 py-2`}
                    onClick={() => onPayment(row)}
                  >
                    Payment
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? "bg-red-500 text-white" : "text-gray-900"} w-full px-4 py-2`}
                    onClick={() => onCancel(row)}
                  >
                    Cancel Order
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const filteredData = data.filter(
    (item) =>
      item.purchase_order_number &&
      item.purchase_order_number.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div className="font-poppins">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by Purchase Order Number..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          className="border p-2 rounded-md"
        />
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        customStyles={{
          rows: { style: { minHeight: "60px", fontSize: "14px" } },
          headCells: { style: { fontWeight: "bold", fontSize: "16px", textAlign: "center" } },
        }}
      />
    </div>
  );
};

export default PurchaseOrderTable;
