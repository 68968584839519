import React from 'react'
import { Outlet } from 'react-router-dom';


const Dashboard = () => {
  return (
    <div className='flex h-[100vh]  w-full items-center justify-center'>
     <h1 className='font-medium text-blue-600 text-3xl'>Welcome To Dashboard</h1>
      <Outlet/>
    </div>
  )
}

export default Dashboard ;
