import React from 'react';

export const FormButton = ({ type,func, title, customcls }) => {
  return (
    <button
    type={type}
      onClick={func}
      className={`flex items-center text-18px-vw justify-center px-4 py-[0.5vw] font-semibold rounded-[0.24vw] transition duration-300 ease-in-out transform focus:outline-none ${
        customcls ? customcls : 'bg-[#0074ff] hover:bg-blue-700 text-white'
      }`}
    >
      {title}
    </button>
  );
};

export default FormButton;
