import React from "react";
import { Button } from "@mui/material";

const CustomButton = ({
  variant = "outlined",
  onClick, 
 margin,
  isEditing,
  text,
  bgColor = "#0074ff1a",
  textColor = "#0074ff",
  fontWeight = 600,
  borderRadius = "8px",
  type = "submit",
  border = "none",
  padding = "10px 20px",
  ...props
}) => {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      sx={{
        bgcolor: bgColor,
        color: textColor,
        fontWeight: fontWeight,
        borderRadius: borderRadius,
        border: border,
        padding: padding, 
        margin:margin,
        boxShadow: "none", // Remove shadow
        textTransform: "none", // Prevent uppercase text
        transition: "background-color 0.3s, color 0.3s",
        "&:hover": {
          boxShadow: "none", // Remove hover shadow
          bgcolor: bgColor, // Optionally, keep the same background color on hover
        },
        ...props.sx,
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
