import React, { useState } from "react";
import { Outlet } from "react-router-dom";


const Index = () => {
 
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Index; // Make sure to also update the export statement.
