import React, { useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import logo from "../../assets/logo.png";
import { checkUser, logoutReq, postReq, postReqLogin } from "../../apis/api";
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";
import { reactIcons } from "../../utils/icons";
import { useDispatch } from "react-redux";
import { loginSuccess, logoutSuccess, setError } from "../../slices/authSlice";
import Loader from "../../components/loader/Loading";

const Login = () => {
  const [step, setStep] = useState(1);
  const [contactNumber, setContactNumber] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [roleId, setRoleId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [isAgreed, setIsAgreed] = useState(true);
  const [errors, setErrors] = useState({
    contactNumber: "",
    password: "",
    otp: "",
  }); // Error state
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleContactSubmit = async (e) => {
    if (!isAgreed) {
      toast.error("Please Mark Checkbox");
      return;
    }

    e.preventDefault();
    if (contactNumber.length !== 10 || !/^\d+$/.test(contactNumber)) {
      setErrors({
        ...errors,
        contactNumber: "Please enter a valid 10-digit phone number.",
      });
      return;
    }
    setErrors({ ...errors, contactNumber: "" }); // Clear error if valid

    try {
      setIsLoading(true);
      const response = await checkUser(contactNumber);
      setMessage(response.data.message);
      if (response.data.status) {
        const { role_id } = response.data;
        setRoleId(role_id);
        role_id === 1 ? setStep(3) : setStep(2);
        toast.success("Contact number found. Proceeding to next step.");
      } else {
        toast.error(response.data.message || "Contact number not found.");
      }
    } catch (error) {
      toast.error(message || "Error in contact number.");
    } finally {
      setIsLoading(false);
    }
  }; 

  const handleSessionTimeout = (expiry) => {
    const [hours, minutes, seconds] = expiry.split(":").map(Number);
    const expirationTime = hours * 3600 + minutes * 60 + seconds;
  
    const logoutTime = Date.now() + expirationTime * 1000;
    
    // Log calculated expiration time and current time for debugging
    console.log("Calculated expiration time in seconds:", expirationTime);
    console.log("Current time:", new Date().toLocaleString());
    console.log("Logout time (based on token expiry):", new Date(logoutTime).toLocaleString());
  
    const timeoutId = setTimeout(async () => {
      console.log("Session expired. Logging out...");
  
      try {
        const response = await logoutReq();
        if (response.status === 200) {
          dispatch(logoutSuccess());
          toast.error("Session timed out. Please log in again.");
          navigate('/session-timeout');
        } else {
          toast.error(`Logout failed: ${response.message}`);
        }
      } catch (error) {
        toast.error(`Error during logout: ${error.message || "Unexpected error"}`);
      }
    }, expirationTime * 1000);
  
    // Log timeout setup
    console.log("Timeout set for session expiration.");
  
    // Clear the timeout on unmount or new session
    return () => clearTimeout(timeoutId);
  };


  const handlePasswordSubmit = async (e) => {
    if (!isAgreed) {
      toast.error("Please mark the checkbox");
      return;
    }

    e.preventDefault();
    if (password.length < 6) {
      setErrors({ password: "Password must be at least 6 characters long." });
      return;
    }
    setErrors({ password: "" });

    try {
      setIsLoading(true);

      const data = {
        phone: contactNumber,
        password: password,
        role_id: roleId,
      };

      const response = await postReqLogin(data, "/login");

      // Check if response has a message to display
      if (response.data.message) {
        toast.info(response.data.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }

      if (response.status === 200) {
        const { token } = response.data;
        const decoded = jwtDecode(token);

        const { user_id, username, role_id, expiry } = decoded.data;
        const user = { user_id, username, role_id };

        dispatch(loginSuccess({ token, user }));
        toast.success("Logged in Successfully", {
          position: "top-right",
          autoClose: 5000,
        });
        navigate("/dashboard");
           
       // Log token and expiration details for debugging
       console.log("Decoded token data:", decoded.data);
       console.log("Token expiration time from server:", expiry);
 
       // Session timeout handling
       handleSessionTimeout(expiry);

       } else {
        // For all other non-200 responses, show the message
        toast.error(response.data.message || "Login failed.", {
          position: "top-right",
          autoClose: 5000,
        });
        dispatch(setError(response.data.message || "Login failed."));
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error(
        error.response?.data?.message || "Error during admin login.",
        {
          position: "top-right",
          autoClose: 5000,
        }
      );
      dispatch(setError("Error during admin login."));
    } finally {
      setIsLoading(false);
    }
  }; 





  // Handle OTP form submission for non-admin users
  const handleOtpSubmit = async (e) => {
    if (!isAgreed) {
      toast.error("Please Mark Checkbox");
      return;
    }
    e.preventDefault();
    const otpString = otp.join("");

    if (otpString.length !== 4 || !/^\d+$/.test(otpString)) {
      setErrors({ ...errors, otp: "Please enter a valid 4-digit OTP." });
      return;
    }
    setErrors({ ...errors, otp: "" }); // Clear error if valid

    try {
      setIsLoading(true);
      const data = {
        phone: contactNumber,
        password: otpString,
        role_id: roleId,
      };
      const response = await postReq(data, "/login");

      if (response.data.success) {
        toast.success("OTP login successful");
        navigate("/");
      } else {
        toast.error(response.data.message || "Incorrect OTP.");
      }
    } catch (error) {
      toast.error("Error during OTP login.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle OTP input changes
  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  return (
    <div className="flex flex-row font-poppins items-center bg-white justify-center w-[100vw] min-h-screen">
      <ToastContainer />
      <div className="w-[35%] hidden h-screen relative bg-white md:flex flex-col items-center justify-center">
        <div className="absolute top-[1.5vw] left-[1.5vw] cursor-pointer z-10">
          <button
            onClick={() => navigate(-1)}
            className=" md:text-[2vw] hover:text-[#4682B4] transition-all"
          >
            {reactIcons.longBack}
          </button>
        </div>

        <div className="flex flex-col items-center justify-center gap-[2vw] min-h-screen bg-gradient-to-b from-white to-gray-100 relative p-6">
          <img src={logo} alt="Logo" className="w-[14vw]" />
          <h1 className="text-[2vw] font-bold text-center md:mb-[3vw] text-gray-800">
            Welcome to{" "}
            <span className="text-[#4682B4]">
              Shree Gopal Engineering Works
            </span>
          </h1>
          <div className="absolute bottom-[2vw]">
            <div className="text-center text-gray-600 text-[1vw]">
              © Copyright 2024 Shree Gopal Engineering Works. All Rights
              Reserved
            </div>
          </div>
        </div>
      </div>

      <div className="w-full loginRightSection bg-cover h-screen flex items-center justify-center">
        <div className="bg-white shadow-lg w-full h-[35%] md:h-auto md:w-[35vw] p-[2vw] px-[8vw] md:px-[1.5vw] md:pb-[3vw] flex flex-col justify-center items-center rounded-xl">
          <img src={logo} alt="Logo" className="block md:hidden w-[18vw]" />
          <h1 className="text-[5vw] md:text-[1.5vw] font-semibold text-center md:mb-24px-vw text-gray-800">
            Sign in to your account
          </h1>

          {step === 1 && (
            <form
              onSubmit={handleContactSubmit}
              className="w-full flex flex-col"
            >
              <input
                type="text"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                className="w-full py-[1.5vw] md:py-[0.7vw] text-24px-vw px-[1vw] border border-gray-300 focus:border-blue-500 focus:outline-none transition-all duration-200 rounded-md shadow-sm"
                placeholder="Enter Your Phone Number"
                required
              />
              {errors.contactNumber && (
                <span className="text-red-500 text-sm">
                  {errors.contactNumber}
                </span>
              )}

              <button className="w-full text-center  items-center justify-center  mt-6 py-[1.5vw] md:py-[.8vw] bg-gradient-to-r from-blue-500 to-blue-700 hover:bg-gradient-to-l transition-transform duration-300 ease-in-out text-white font-semibold rounded-lg shadow-lg">
                Next
                {isLoading && (
                  <span className="animate-spin inline-block px-[1vw] text-20">
                    {reactIcons.spinLoader}
                  </span>
                )}
              </button>
            </form>
          )}

          {step === 2 && (
            <form
              onSubmit={handlePasswordSubmit}
              className="w-full flex flex-col"
            >
              <label className="block mb-2 text-sm font-medium text-gray-600">
                Password
              </label>
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full py-[1.5vw] md:py-[0.7vw] text-24px-vw px-[1vw] border border-gray-300 focus:border-blue-500 focus:outline-none transition-all duration-200 rounded-md shadow-sm"
                  placeholder="Enter Your Password"
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 focus:outline-none"
                >
                  {showPassword ? (
                    <FaEyeSlash className="text-gray-500" />
                  ) : (
                    <FaEye className="text-gray-500" />
                  )}
                </button>
              </div>
              {errors.password && (
                <span className="text-red-500 text-sm">{errors.password}</span>
              )}
              <button className="w-full text-center  items-center justify-center  mt-6 py-[1.5vw] md:py-[.8vw] bg-gradient-to-r from-blue-500 to-blue-700 hover:bg-gradient-to-l transition-transform duration-300 ease-in-out text-white font-semibold rounded-lg shadow-lg">
                Login
                {isLoading && (
                  <span className="animate-spin inline-block px-[1vw] text-20">
                    {reactIcons.spinLoader}
                  </span>
                )}
              </button>
            </form>
          )}

          {step === 3 && (
            <form onSubmit={handleOtpSubmit} className="w-full flex flex-col">
              <label className="block mb-2 text-24px-vw font-medium text-gray-600">
                OTP
              </label>
              <div className="flex justify-evenly ">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    maxLength="1"
                    className="w-12 h-12 text-center text-lg border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none transition-all duration-200"
                  />
                ))}
              </div>
              {errors.otp && (
                <span className="text-red-500 text-sm">{errors.otp}</span>
              )}
              <button className="w-full text-center  items-center justify-center  mt-6 py-[1.5vw] md:py-[.8vw] bg-gradient-to-r from-blue-500 to-blue-700 hover:bg-gradient-to-l transition-transform duration-300 ease-in-out text-white font-semibold rounded-lg shadow-lg">
                Verify Otp
                {isLoading && (
                  <span className="animate-spin inline-block px-[1vw] text-20">
                    {reactIcons.spinLoader}
                  </span>
                )}
              </button>
            </form>
          )}

          <div className="flex items-start mt-16px-vw">
            <input
              type="checkbox"
              id="terms"
              checked={isAgreed}
              onChange={() => setIsAgreed(!isAgreed)}
              className="mr-2 mt-8px-vw px-vw rounded border-gray-300 focus:border-blue-500 focus:ring-blue-500"
              required
            />
            <label htmlFor="terms" className="text-20px-vw text-gray-600">
              By signing in, you acknowledge and agree to our{" "}
              <span className="text-blue-600 cursor-pointer">
                terms and conditions
              </span>
              .
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
