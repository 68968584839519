import React from "react";
import "./Loading.css"; 
const Loader = () => {
  return (
    <div className="loader-container ">
      <div className="loader">
        <svg className="circular-loader" viewBox="25 25 50 50">
          <circle
            className="loader-path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke="#3498db"
            strokeWidth="5"
          />
        </svg>
      </div>
      <p className="loader-text">Loading...</p>
    </div>
  );
};

export default Loader;
