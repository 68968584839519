import React from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => { 
    const navigate=useNavigate();
  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <section className="flex flex-col items-center justify-center h-screen bg-cover bg-center bg-no-repeat" style={{ backgroundImage: "url('/path/to/your/image.jpg')" }}>
        <div className="text-center">
          <h1 className="text-5xl font-bold mb-4">Welcome to SGEW Landing Page</h1>
          <p className="text-lg mb-6">Explore innovative solutions for your business growth.</p>
          <a href="#features" className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-300"
          onClick={()=>navigate("/login")}
          >Get Started</a>
        </div>
      </section>

      {/* Features Section */}
 

      {/* Testimonials Section */}
   
    </div>
  );
};

export default LandingPage;
