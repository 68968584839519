import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { TextField, Grid, Typography, Divider, IconButton, Button, MenuItem } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import FormButton from '../../components/Button';
import { useParams } from 'react-router-dom';
import { getReq } from '../../apis/api';

const PurchaseOrderForm = () => {
  const { id } = useParams();
  const [itemsList, setItemsList] = useState([]);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      purchaseOrderNumber: '',
      supplierID: '',
      orderDate: '',
      expectedDeliveryDate: '',
      invoiceNumber: '',
      invoiceDate: '',
      ewayNumber: '',
      invoicePDF: '',
      logisticsAmount: '',
      carriageAmount: '',
      status: '',
      items: [],
      totalInvoiceAmount: '',
      roundedOffAmount: '',
      totalWithGST: '',
      grandTotal: '',
      comment: '',
    },
  });

  const [isEditing, setIsEditing] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  useEffect(() => {
    // Fetch purchase data if in view/edit mode
    if (id) {
      getReq(`fetchPurchase/${id}`)
        .then(response => {
          const data = response.data[0]; // Adjusted to match your original structure
          // Map API data to form structure
          const mappedData = {
            purchaseOrderNumber: data.purchase_order_number,
            supplierID: data.supplier_id,
            orderDate: data.order_date,
            expectedDeliveryDate: data.expected_delivery_date,
            invoiceNumber: data.invoice_number,
            invoiceDate: data.invoice_date,
            ewayNumber: data.eway_number,
            invoicePDF: data.invoice_pdf,
            logisticsAmount: data.logistics_amount,
            carriageAmount: data.carriage_amount,
            status: data.status,
            comment: data.comment,
            items: response.data.map(item => ({
              itemName: item.item_id, // Adjust to display actual item name if needed
              hsn: item.item_hsn,
              quantity: item.item_qty,
              length_mm: item.length_mm,
              width_mm: item.width_mm,
              thickness_mm: item.thickness_mm,
              weight_kg: item.weight_kg,
              supplierAmount: item.supplier_amount,
              carriageAmount: item.carriage_amount,
              logisticsAmount: item.logistics_amount,
              sgewPrice: item.sgew_price,
              totalInvoiceAmount: item.total_invoice_amount,
              roundOfAmount: item.round_of_amount,
            })),
          };
          reset(mappedData);
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [id, reset]);
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await getReq("/fetchItems");
        if (response.status === 200) {
          const fetchedItems = response.data.map((item) => ({
            id: item.item_id,
            name: item.item_name,
            category: item.item_category,
            hsn: item.hsn,
            tag: item.item_tag,
            unit_price: item.unit_price,
            length_mm: item.default_length_mm,
            width_mm: item.default_width_mm,
            thickness_mm: item.default_thickness_mm,
            weight_kg: item.default_weight_kg,
          }));

          setItemsList(fetchedItems);
        } else {
          console.error("Error fetching items:", response.message);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchItems();
  }, []);

  const ResetData = () => (
    setIsEditing(true),
    {
    purchaseOrderNumber: '',
    supplierID: '',
    orderDate: '',
    expectedDeliveryDate: '',
    invoiceNumber: '',
    invoiceDate: '',
    ewayNumber: '',
    invoicePDF: '',
    logisticsAmount: '',
    carriageAmount: '',
    status: '',
    comment: '',
    items: [
      {
        itemName: '',
        hsn: '',
        quantity: '',
        length_mm: '',
        width_mm: '',
        thickness_mm: '',
        weight_kg: '',
        supplierAmount: '',
        sgewPrice: '',
        totalInvoiceAmount: '',
        roundOfAmount: '',
      }
    ],
  });
  
  

  const handleEdit = () => {
    setIsEditing(true);
    
    console.log(isEditing,"asdfghjk");
  };
  const handleCancel = () => {
    setIsEditing(false);
  };
  const handleCreateNewInvoice = () => {
    setIsEditing(true);
    reset(); // Clears all fields for a new invoice
  };

  const onSubmit = (data) => {
    console.log('Form submitted:', data);
    setIsEditing(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', margin: '0 auto', padding: '20px', background: '#f9f9f9', borderRadius: '8px' }}>
      <Typography variant="h5" gutterBottom>
        {isEditing ? "Edit Purchase Order / Create New Purchase Invoice" : "View Purchase Order / Purchase Invoice"}
      </Typography>
      <Divider />

      <Grid container spacing={2} className="pt-6">
        {/* Basic Details */}
        <Grid item xs={12} sm={3}>
          <Controller name="purchaseOrderNumber" control={control} render={({ field }) => (
            <TextField {...field} label="Purchase Order Number" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="supplierID" control={control} render={({ field }) => (
            <TextField {...field} label="Supplier ID" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="orderDate" control={control} render={({ field }) => (
            <TextField {...field} label="Order Date" type="date" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="expectedDeliveryDate" control={control} render={({ field }) => (
            <TextField {...field} label="Expected Delivery Date" type="date" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="invoiceNumber" control={control} render={({ field }) => (
            <TextField {...field} label="Invoice Number" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="invoiceDate" control={control} render={({ field }) => (
            <TextField {...field} label="Invoice Date" type="date" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="ewayNumber" control={control} render={({ field }) => (
            <TextField {...field} label="Eway Number" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="invoicePDF" control={control} render={({ field }) => (
            <TextField {...field} type="file" label="Invoice PDF(s)" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="logisticsAmount" control={control} render={({ field }) => (
            <TextField {...field} label="Logistics Amount" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="carriageAmount" control={control} render={({ field }) => (
            <TextField {...field} label="Carriage Amount" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="status" control={control} render={({ field }) => (
            <TextField {...field} label="Status" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12}>
          <Controller name="comment" control={control} render={({ field }) => (
            <TextField {...field} label="Comment" multiline rows={2} fullWidth disabled={!isEditing} />
          )} />
        </Grid>

        {/* Items Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Items Included</Typography>
          <Divider />
        </Grid>

        {fields.map((item, index) => (
  <Grid container spacing={2} key={item.id} alignItems="center" style={{ marginBottom: '16px', marginTop: '10px', marginLeft: '4px' }}>
  
    <Grid item xs={12} sm={6} md={2}>
                      <Controller
                       name={`items[${index}].itemName`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} select fullWidth label="Item Name" variant="outlined">
                            {itemsList?.map(itm => (
                              <MenuItem key={itm.id} value={itm.id}>{itm.name}</MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>



    <Grid item xs={12} sm={2} md={1}>
      <Controller name={`items[${index}].hsn`} control={control} render={({ field }) => (
        <TextField {...field} label="HSN" fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    <Grid item xs={12} sm={1} md={1}>
      <Controller name={`items[${index}].quantity`} control={control} render={({ field }) => (
        <TextField {...field} label="Quantity" fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    <Grid item xs={12} sm={2} md={1}>
      <Controller name={`items[${index}].supplierAmount`} control={control} render={({ field }) => (
        <TextField {...field} label="Supplier Price" fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    <Grid item xs={12} sm={2} md={1}>
      <Controller name={`items[${index}].cgstSgst`} control={control} render={({ field }) => (
        <TextField {...field} label="CGST+SGST/IGST" fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    <Grid item xs={12} sm={2} md={1}>
      <Controller name={`items[${index}].logisticsAmount`} control={control} render={({ field }) => (
        <TextField {...field} label="LogisticsAmount" fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    <Grid item xs={12} sm={2} md={1}>
      <Controller name={`items[${index}].carriageAmount`} control={control} render={({ field }) => (
        <TextField {...field} label="CarriageAmount" fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    <Grid item xs={12} sm={2} md={1}>
      <Controller name={`items[${index}].l+c`} control={control} render={({ field }) => (
        <TextField {...field} label="L+C" fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    <Grid item xs={12} sm={2} md={1}>
      <Controller name={`items[${index}].sgewPrice`} control={control} render={({ field }) => (
        <TextField {...field} label="SGEW Price" fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    <Grid item xs={12} sm={2} md={1}>
      <Controller name={`items[${index}].weight`} control={control} render={({ field }) => (
        <TextField {...field} label="Weight (kg)" fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    
    {isEditing && (
      <>
        {/* Delete Button */}
        <Grid item xs={0.5}>
          <IconButton onClick={() => remove(index)} color="error">
            <DeleteIcon />
          </IconButton>
        </Grid>

        {/* Add Item Button - Only on last item */}
        {index === fields.length - 1 && (
          <Grid item xs={0.5}>
            <IconButton onClick={() => append({})} color="primary">
              <AddIcon />
            </IconButton>
          </Grid>
        )}
      </>
    )}
  </Grid>
))}


        {/* Totals Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Totals</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="totalInvoiceAmount" control={control} render={({ field }) => (
            <TextField {...field} label="Total Invoice Amount" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="roundedOffAmount" control={control} render={({ field }) => (
            <TextField {...field} label="Rounded Off Amount" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="totalWithGST" control={control} render={({ field }) => (
            <TextField {...field} label="Total with GST" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="grandTotal" control={control} render={({ field }) => (
            <TextField {...field} label="Grand Total" fullWidth disabled={!isEditing} />
          )} />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="end" style={{ marginTop: '20px' }}>
        {isEditing ? (
          <div className='flex gap-1'>
 <FormButton type="submit" title={"Save Changes"}  color="primary" variant="contained"/>
 {/* <FormButton  title={"cancle"}  color="primary" variant="contained"/> */}
 <FormButton
                 func={handleCancel}
                  title="cancel"
                  customcls=" bg-[#0074ff] text-[#ffff] border border-[#d0412e33]"
                />
          </div>
        ) : (
       <div className='flex justify-start gap-1'>
          <button onClick={handleEdit} >
          <FormButton
 
        title="Edit"
         customcls="bg-[#0074ff] text-[#ffff] border border-[#d0412e33]"
/>
          </button>
          <button onClick={ResetData} >
          <FormButton
 
        title="Create New Invoice"
         customcls="bg-[#0074ff] text-[#ffff] border border-[#d0412e33]"
/>
          </button>
           
       </div>
        )}
        
      </Grid>
    </form>
  );
};

export default PurchaseOrderForm;
