import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center w-full min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800">404</h1>
        <p className="text-2xl mt-4 text-gray-600">Oops! Page Not Found</p>
        <p className="text-lg mt-2 text-gray-500">The page you are looking for doesn't exist or an error occurred.</p>
        <Link to="/" className="mt-6 inline-block px-6 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 transition">
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
