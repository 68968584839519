import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Grid,
  InputAdornment,
} from "@mui/material";
import Button from "../../Button";
import CloseIcon from "@mui/icons-material/Close";
import CategoryIcon from "@mui/icons-material/Category";
import { useRef } from "react";
import ScaleIcon from "@mui/icons-material/Scale";
import { FaDollarSign, FaUser } from "react-icons/fa";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getReq, nestedPostReq, postReq } from "../../../apis/api";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add"; // Import AddIcon for the plus icon
import { BiRupee } from "react-icons/bi";
import { yupResolver } from "@hookform/resolvers/yup";
import { splitFormSchema } from "../../../utils/validation";

const SplitModal = ({ open, handleClose, itemData, ItemId }) => {
  const [categories, setCategories] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [employess, setEmployess] = useState([]);
  const [toastShown, setToastShown] = useState(false);
  const [selectedSubitems, setSelectedSubitems] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [isAddingSubitem, setIsAddingSubitem] = useState(false);

  const fetchItemDetails = async () => {
    try {
      const response = await getReq(`/fetchItem/${ItemId}`);
      if (response.status === 200) {
        setItemDetails(response.data);
      } else {
        toast.error("Failed to fetch item details.");
      }
    } catch (error) {
      toast.error("Error fetching item details.");
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(splitFormSchema),
    defaultValues: {
      item_id: itemData?.item_id || "",
      item_name: itemData?.item_name || "",
      item_weight: 500,
      total_available_weight: "",
      sgew_price: itemData?.unit_price || 0,
      subitems: [
        {
          subitem_id: "",
          length: "",
          width: "",
          thickness: "",
          weight: "",
          quantity: "",
          sgew_price: "",
          created_by: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "subitems",
  });

  const fetchCategories = async () => {
    try {
      const res = await getReq("/subitems");
      setCategories(res.data || []);
    } catch (e) {
      console.error("Error fetching categories:", e);
      toast.error("Error in fetching categories");
    }
  };
  const fetchEmployess = async () => {
    try {
      const res = await getReq("/fetchEmployee");
      setEmployess(res.data || []);
    } catch (e) {
      console.error("Error fetching employess:", e);
      toast.error("Error in fetching employess");
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchItemDetails();
    fetchEmployess();
  }, []);

  const totalAvailableWeight = watch("item_weight");
  const totalSgewPrice = watch("sgew_price");

  // Watch for changes in subitem weights and quantities to validate total weight
  const subitems = useWatch({ control, name: "subitems" });

  

  useEffect(() => {
    const totalWeightUsed = subitems.reduce((total, subitem) => {
      const itemWeight = subitem.weight || 0;
      const quantity = subitem.quantity || 0;
      const scrapWeight = (subitem.scrap_weight_kg || 0) * quantity; // Calculate total scrap weight per subitem
      return total + itemWeight * quantity + scrapWeight; // Include scrap weight in the total weight
    }, 0);

    // Show toast if total weight exceeds available weight
    if (totalWeightUsed > totalAvailableWeight) {
      if (!toastShown) {
        toast.error("Not sufficient available weight.");
        setToastShown(true);
      }
    } else {
      setToastShown(false);
    }

    // Calculate total quantity of subitems
    const totalQuantity = subitems.reduce((total, subitem) => {
      const quantity = parseInt(subitem.quantity, 10) || 0;
      return total + quantity;
    }, 0);

    // Calculate unit price for each subitem (excluding fees)
    const unitPrice =
      totalQuantity > 0
        ? (totalSgewPrice * totalAvailableWeight) / totalQuantity
        : 0;

    // Calculate sgew_price including each subitem's processing fee
    subitems.forEach((subitem, index) => {
      const processingFee = parseFloat(subitem.processing_fees || 0);
      const calculatedPrice = parseFloat(unitPrice) + processingFee;
      const roundedPrice = calculatedPrice.toFixed(2);

      if (subitem.sgew_price !== roundedPrice) {
        setValue(`subitems.${index}.sgew_price`, parseFloat(roundedPrice));
      }
    });

    // Set available weight by subtracting the used weight (including scrap weight)
    setValue("total_available_weight", totalAvailableWeight - totalWeightUsed);
  }, [subitems, totalAvailableWeight, totalSgewPrice, setValue, toastShown]);

  const handleSubitemChange = (subitemId, index) => {
    const selectedSubitem = categories.find((item) => item.id === subitemId);
    if (selectedSubitem) {
      setValue(`subitems.${index}.length`, selectedSubitem.default_length_mm);
      setValue(`subitems.${index}.width`, selectedSubitem.default_width_mm);
      setValue(
        `subitems.${index}.thickness`,
        selectedSubitem.default_thickness_mm
      );
      setValue(`subitems.${index}.weight`, selectedSubitem.default_weight_kg);
      setValue(`subitems.${index}.sgew_price`, selectedSubitem.sgew_price);
      setValue(
        `subitems.${index}.processing_fees`,
        selectedSubitem.processing_fees
      );
      setValue(
        `subitems.${index}.scrap_weight_kg`,
        selectedSubitem.scrap_weight_kg
      );

      // Update selected subitems state
      setSelectedSubitems((prevSelected) => [...prevSelected, subitemId]);
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              fontSize: "1vw",
            },
            "& .MuiInputLabel-root": {
              fontSize: "1vw",
            },
          },
        },
      },
    },
  });

  const onSubmit = async (data) => {
    console.log("Split Item Data", data);

    const totalWeightUsed = data.subitems.reduce((total, subitem) => {
      return total + (subitem.weight || 0) * (subitem.quantity || 0);
    }, 0);

    if (totalWeightUsed > totalAvailableWeight) {
      toast.error("Not sufficient available weight in items.");
      return;
    }

    data.total_available_weight = totalWeightUsed;

    try {
      const response = await nestedPostReq(data, "/split-item");
      console.log("Response:", response.data);

      // Close the modal on success
      if (response.status === 200) {
        reset();
        setEditingIndex(null);
        toast.success("Item Splitted successfully!");
        reset();
        handleClose();
      } else {
        // Show error message for non-200 responses
        toast.error(response.data.message || "Item Not Splited", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages
      ) {
        const errorMsg = error.response.data.messages;

        // Display error message in toast
        Object.values(errorMsg).forEach((msg) => {
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  };

  const handleAddSubitem = () => {
    setIsAddingSubitem(!isAddingSubitem);
    append({
      subitem_id: "",
      length: "",
      width: "",
      thickness: "",
      weight: "",
      quantity: "",
      sgew_price: "",
    });
  };

  // Remove subitem and update selectedSubitems state
  const handleRemoveSubitem = (index, subitemId) => {
    remove(index);
    setSelectedSubitems((prevSelected) =>
      prevSelected.filter((id) => id !== subitemId)
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle className="flex items-center justify-between">
            <Typography variant="h6">Split Item</Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              Item Details
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="item_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Item Id"
                      placeholder="Enter Item Id"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CategoryIcon />
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      error={!!errors.item_id}
                      helperText={errors.item_id?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="item_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Item name"
                      placeholder="Enter Item name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CategoryIcon />
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      error={!!errors.item_name}
                      helperText={errors.item_name?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="item_weight"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Item Available Weight"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ScaleIcon />
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      error={!!errors?.item_weight}
                      helperText={errors.item_weight?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="sgew_price"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Item SGEW Price per(kg)"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BiRupee />
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      error={!!errors.sgew_price}
                      helperText={errors.sgew_price?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
              SubItems
            </Typography>
            {fields.map((item, index) => (
              <Grid container spacing={2} key={item.id} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6} md={2} className="flex items-center">
                  <Controller
                    name={`subitems.${index}.subitem_id`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Select SubItem"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e);
                          handleSubitemChange(e.target.value, index);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CategoryIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.subitems?.[index]?.subitem_id}
                        helperText={
                          errors.subitems?.[index]?.subitem_id?.message
                        }
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                maxHeight: 250,
                                "& .MuiMenuItem-root": {
                                  typography: "body2",
                                  fontSize: "1vw",
                                },
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          },
                        }}
                      >
                        {categories.map((subItem) => (
                          <MenuItem key={subItem.id} value={subItem.id}>
                            {subItem.subitem_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <Controller
                    name={`subitems.${index}.quantity`} // Bind quantity to each subitem by index
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Quantity"
                        placeholder="Enter Quantity"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <StraightenIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.subitems?.[index]?.quantity}
                        helperText={errors.subitems?.[index]?.quantity?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <Controller
                    name={`subitems.${index}.sgew_price`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="SGEW Price Per SubItem"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BiRupee />
                            </InputAdornment>
                          ),
                          readOnly: true,
                        }}
                        error={!!errors.subitems?.[index]?.sgew_price}
                        helperText={
                          errors.subitems?.[index]?.sgew_price?.message
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2} className="flex items-center">
                  <Controller
                    name={`subitems.${index}.created_by`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Select Employee"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e);
                          handleSubitemChange(e.target.value, index);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CategoryIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.subitems?.[index]?.created_by}
                        helperText={
                          errors.subitems?.[index]?.created_by?.message
                        }
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                maxHeight: 250,
                                "& .MuiMenuItem-root": {
                                  typography: "body2",
                                  fontSize: "1vw",
                                },
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          },
                        }}
                      >
                        {employess.map((emp) => (
                          <MenuItem key={emp.id} value={emp.id}>
                            {emp.user_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} className="flex items-center">
                  <div className="flex items-center">
                    <div>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {`L: ${watch(`subitems[${index}].length`) || "0"}, W: ${
                          watch(`subitems[${index}].width`) || "0"
                        }, WT: ${
                          watch(`subitems[${index}].weight`) || "0"
                        }, THK: ${
                          watch(`subitems[${index}].thickness`) || "0"
                        }`}
                      </Typography>
                    </div>

                    {/* Edit Icon for Subitem */}
                    <div className="flex items-center ml-4 space-x-4">
                      {/* Add Subitem Button */}
                      {index === fields.length - 1 && (
                        <IconButton
                          onClick={handleAddSubitem}
                          color="primary"
                          size="small"
                          className="bg-blue-100 text-blue-600 hover:bg-blue-200 hover:text-blue-800 transition duration-300 shadow-md rounded-full"
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      )}

                      {/* Edit Subitem Button */}
                      <IconButton
                        onClick={() =>
                          setEditingIndex(editingIndex === index ? null : index)
                        }
                        size="small"
                        className="bg-yellow-100 text-yellow-600 hover:bg-yellow-200 hover:text-yellow-800 transition duration-300 shadow-md rounded-full"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>

                      {/* Remove Subitem Button */}

                      {index > 0 && (
                        <IconButton
                          onClick={() => remove(index)}
                          size="small"
                          className="bg-red-100 text-red-600 hover:bg-red-200 hover:text-red-800 transition duration-300 shadow-md rounded-full"
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </Grid>

                {/* Editable Fields for the Selected Subitem */}
                {editingIndex === index && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name={`subitems.${index}.length`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Length"
                            type="number"
                            error={!!errors.subitems?.[index]?.length}
                            helperText={
                              errors.subitems?.[index]?.length?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name={`subitems.${index}.width`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Width"
                            type="number"
                            error={!!errors.subitems?.[index]?.width}
                            helperText={
                              errors.subitems?.[index]?.width?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name={`subitems.${index}.thickness`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Thickness"
                            type="number"
                            error={!!errors.subitems?.[index]?.thickness}
                            helperText={
                              errors.subitems?.[index]?.thickness?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name={`subitems.${index}.weight`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Weight"
                            type="number"
                            error={!!errors.subitems?.[index]?.weight}
                            helperText={
                              errors.subitems?.[index]?.weight?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
          </DialogContent>
          <DialogActions className=" md:mr-14px-vw md:mb-10px-vw ">
            <Button
              func={handleClose}
              title={"Cancel"}
              customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
            />
            <Button
              type="submit"
              func={handleSubmit(onSubmit)}
              title={"Submit"}
            />
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default SplitModal;
