import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getReq, postReq } from "../../apis/api"; // Your API handlers
import {
  FaEnvelope,
  FaLock,
  FaPhone,
  FaLink,
  FaMapMarkerAlt,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaRegBuilding,
  FaMoneyBillWave,
  FaUser,
  FaFileInvoice,
  FaIdCard,
  FaClock,
  FaRegCalendarAlt,
  FaMoneyCheckAlt,
  FaPercent,
  FaHome,
  FaGlobe,
  FaMap,
  FaFingerprint,
} from "react-icons/fa";

// Import icons
import BreadCrumbs from "../../components/common/BreadCrumbs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import logoPlaceholder from "../../assets/logo.png";
import { toast } from "react-toastify";
import CustomButton from "../../components/button/CustomButton";
import {
  Card,
  CardContent,
  TextField,
  IconButton,
  Typography,
  Button,
} from "@mui/material";

const SettingsForm = () => {
  const [settingsData, setSettingsData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { register, handleSubmit, setValue, watch } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [logoPreview, setLogoPreview] = useState(logoPlaceholder);

  // Fetch settings data on component mount
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getReq("fetchSettingData");
        setSettingsData(result.data[0]); // Assuming data is an array
        populateForm(result.data[0]);
      } catch (error) {
        console.error("Error fetching settings data:", error);
      }
    }
    fetchData();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Populate form with existing data
  const populateForm = (data) => {
    Object.keys(data).forEach((key) => setValue(key, data[key]));
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  // Submit form data
  const onSubmit = async (data) => {
    console.log("Submitting form data:", data);
  
    try {
      // Make the POST request
      const response = await postReq(data, `/updateSettingData`);
      console.log("Response from server:", response.data);
  
      if (response.status === 200) {
        toast.success("Settings updated successfully!");
      } else {
        // If the status is not 200, handle the error accordingly
        if (response.data.errors) {
          // Loop through the errors object and show each error in a toast
          Object.keys(response.data.errors).forEach((key) => {
            toast.error(response.data.errors[key]);
          });
        } else {
          toast.error("Error updating settings");
        }
      }
    } catch (error) {
      console.error("Error updating settings:", error);
  
      // Check if there are any error responses from the server
      if (error.response && error.response.data.errors) {
        // Loop through the errors object and show each error in a toast
        Object.keys(error.response.data.errors).forEach((key) => {
          toast.error(error.response.data.errors[key]);
        });
      } else {
        toast.error("Failed to update settings.");
      }
    }
  };
  
  

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result); // Preview new logo
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="max-w-11/12 p-32px-vw rounded-lg">
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Settings"} />
        <CustomButton
          variant="outlined"
          onClick={toggleEdit}
          isEditing={isEditing}
          text={isEditing ? "Cancel" : "Edit"}
          bgColor={isEditing ? "#0074ff1a" : "#0074ff1a"}
          textColor={isEditing ? "#0074ff" : "#0074ff"}
          fontWeight={600}
          borderRadius="8px"
          border="none"
          padding="10px 20px"
        />
      </div>

      <Card>
        <CardContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full grid md:grid-cols-[30%,70%] "
          >
            <div className="w-full bg-white rounded-lg text-[#10131e]  flex flex-col items-center">
              {/* Logo */}
              <div className="w-[17vw] md:w-[12vw] relative flex flex-col items-center">
                <img
                  src={logoPreview}
                  alt="Company Logo"
                  className="w-full h-full object-contain"
                />
                <label className="block mt-2 text-center">
                  <input
                    type="file"
                    {...register("company_logo")}
                    onChange={handleLogoChange}
                    className="hidden"
                    accept="image/*"
                  />
                  <span className="cursor-pointer bg-[#0074ff1a] text-[#0074ff] font-semibold px-4 py-2 rounded-[8px] flex items-center justify-center">
                    <FiUpload className="text-[#0074ff] mr-2" />
                    Change Logo
                  </span>
                </label>
              </div>

              {/* Company Details */}
              <div className="w-full flex flex-col md:mt-50px-vw gap-8 px-40px-vw">
                <Typography
                  variant="h6"
                  className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
                >
                  <FaRegBuilding className="inline mr-2" />
                  Company Details
                </Typography>
                <TextField
                  label="Company Id"
                  variant="outlined"
                  {...register("id")}
                  fullWidth
                  disabled
                  InputProps={{
                    startAdornment: <FaRegBuilding className="mr-2" />,
                  }}
                />
                <TextField
                  label="Company Name"
                  variant="outlined"
                  {...register("company_name")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaRegBuilding className="mr-2" />,
                  }}
                />
                <TextField
                  label="Company Email"
                  variant="outlined"
                  {...register("company_email")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaEnvelope className="mr-2" />,
                  }}
                />
                <TextField
                  label="Company Website"
                  variant="outlined"
                  {...register("company_website")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaLink className="mr-2" />,
                  }}
                />
                <TextField
                  label="Company Phone 1"
                  variant="outlined"
                  {...register("company_phone1")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaPhone className="mr-2" />,
                  }}
                />
                <TextField
                  label="Company Phone 2"
                  variant="outlined"
                  {...register("company_phone2")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaPhone className="mr-2" />,
                  }}
                />
                <TextField
                  label=" Email Receipts"
                  variant="outlined"
                  {...register("receipts")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaMapMarkerAlt className="mr-2" />,
                  }}
                />
              </div>

              {/* Social Media Links */}
              <div className="w-full flex flex-col gap-8 px-40px-vw md:mt-40px-vw">
                <Typography
                  variant="h6"
                  className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
                >
                  <FaLink className="inline mr-2" /> Social Media Links
                </Typography>
                <TextField
                  label="Facebook"
                  variant="outlined"
                  {...register("facebook")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaFacebook className="mr-2" />,
                  }}
                />
                <TextField
                  label="Instagram"
                  variant="outlined"
                  {...register("instagram")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaInstagram className="mr-2" />,
                  }}
                />
                <TextField
                  label="Twitter"
                  variant="outlined"
                  {...register("twitter")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaTwitter className="mr-2" />,
                  }}
                />
                <TextField
                  label="LinkedIn"
                  variant="outlined"
                  {...register("linkedin")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaLinkedin className="mr-2" />,
                  }}
                />
                <TextField
                  label="YouTube"
                  variant="outlined"
                  {...register("youtube")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaYoutube className="mr-2" />,
                  }}
                />
              </div>
            </div>

            {/* SMTP Settings */}
            <div className="w-full flex flex-col md:mt-50px-vw gap-8  pb-4">
              <Typography
                variant="h6"
                className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
              >
                <FaEnvelope className="inline mr-2" /> SMTP Setting
              </Typography>
              <div className="grid md:grid-cols-2 grid-cols-1 md:gap-40px-vw">
                <TextField
                  label="SMTP Email"
                  variant="outlined"
                  {...register("smtp_email")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaEnvelope className="mr-2" />,
                  }}
                />
                <TextField
                  label="SMTP Password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"} // Show/hide password functionality
                  {...register("smtp_password")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaLock className="mr-2" />, // Changed to lock icon
                    endAdornment: (
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </IconButton>
                    ),
                  }}
                />
              </div>

              {/* Bank Details  */}
              <Typography
                variant="h6"
                className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
              >
                <FaRegBuilding className="inline mr-2" /> Bank Details
              </Typography>
              <div className="grid md:grid-cols-2 grid-cols-1 md:gap-40px-vw">
                <TextField
                  label="Account Number"
                  variant="outlined"
                  {...register("bank_account_number")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaMoneyBillWave className="mr-2" />, // Money icon for account number
                  }}
                />
                <TextField
                  label="Account Holder Name"
                  variant="outlined"
                  {...register("bank_acc_holder_name")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaUser className="mr-2" />, // User icon for account holder name
                  }}
                />
                <TextField
                  label="GSTIN Number"
                  variant="outlined"
                  {...register("gstin_number")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaFileInvoice className="mr-2" />, // Invoice icon for GSTIN number
                  }}
                />
                <TextField
                  label="Pan Number"
                  variant="outlined"
                  {...register("pan_number")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaIdCard className="mr-2" />, // ID card icon for PAN number
                  }}
                />
                <TextField
                  label="IFSC Number"
                  variant="outlined"
                  {...register("IFSC")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaEnvelope className="mr-2" />, // Bank icon for IFSC number
                  }}
                />
              </div>

              <Typography
                variant="h6"
                className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
              >
                <FaLink className="inline mr-2" /> Order Details
              </Typography>
              <div className="grid md:grid-cols-2 grid-cols-1 md:gap-40px-vw">
                <TextField
                  label="Delivery Estimated Days"
                  variant="outlined"
                  {...register("delivery_estimated_days")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaClock className="mr-2" />, // Clock icon for delivery time
                  }}
                />
                <TextField
                  label="Payment Due Reminder Days"
                  variant="outlined"
                  {...register("payment_due_reminder_days")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaRegCalendarAlt className="mr-2" />, // Calendar icon for reminder days
                  }}
                />
                <TextField
                  label="Payment Due Reminder Days INR Limit"
                  variant="outlined"
                  {...register("payment_due_reminder_INR_limit")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaMoneyCheckAlt className="mr-2" />, // Money check icon for INR limit
                  }}
                />
                <TextField
                  label="Item SubItem Sell Rates (%)"
                  variant="outlined"
                  {...register("sell_rates")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaPercent className="mr-2" />, // Percent icon for sell rates
                  }}
                />
              </div>

              <Typography
                variant="h6"
                className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
              >
                <FaMapMarkerAlt className="inline mr-2" /> Address Information
              </Typography>
              <div className="grid md:grid-cols-2 grid-cols-1 md:gap-40px-vw">
                <TextField
                  label="Company Address 1"
                  variant="outlined"
                  {...register("company_address1")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaHome className="mr-2" />, // Home icon for address
                  }}
                />
                <TextField
                  label="Company Address 2"
                  variant="outlined"
                  {...register("company_address2")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaHome className="mr-2" />, // Home icon for address
                  }}
                />
                <TextField
                  label="Country"
                  variant="outlined"
                  {...register("country")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaGlobe className="mr-2" />, // Globe icon for country
                  }}
                />
                <TextField
                  label="State"
                  variant="outlined"
                  {...register("state")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaMap className="mr-2" />, // Map icon for state
                  }}
                />
                <TextField
                  label="Village"
                  variant="outlined"
                  {...register("village")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaMapMarkerAlt className="mr-2" />, // Map marker icon for village
                  }}
                />
                <TextField
                  label="Pincode"
                  variant="outlined"
                  {...register("pincode")}
                  fullWidth
                  disabled={!isEditing}
                  InputProps={{
                    startAdornment: <FaFingerprint className="mr-2" />, // Fingerprint icon for pincode
                  }}
                />
              </div>
              <div className="w-full flex items-end h-full justify-end mt-4">
                {/* {isEditing && (
                  <CustomButton
                    // onClick={onsubmit}
                    variant="contained"
                    type="submit"
                    text="Save"
                    disabled={!isEditing}
                    bgColor="#0074ff"
                    textColor="#ffffff"
                    fontWeight={600}
                    borderRadius="8px"
                    padding="10px 20px"
                  />
                )} */}

                {isEditing && (
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isEditing}
                    sx={{
                      bgcolor: "#0074ff",
                      color: "#ffffff",
                      fontWeight: 600,
                      borderRadius: "8px",
                      padding: "10px 20px",
                      boxShadow: "none", // Remove shadow
                      textTransform: "none", // Prevent uppercase text
                      transition: "background-color 0.3s, color 0.3s",
                      "&:hover": {
                        boxShadow: "none", // Remove hover shadow
                        bgcolor: "#0074ff", // Optionally, keep the same background color on hover
                      },
                    }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>

            {/* Submit Button */}
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default SettingsForm;
