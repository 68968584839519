import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom'; // Import Outlet for nested routing
import Dashboard from '../dashboard/index';
import Header from '../../components/header';
import Footer from '../../components/footer/Footer';

const HomePage = () => {
  return (
    <div className=''> {/* Use h-screen for full viewport height */}
      <Header /> {/* Header will always be visible */}
      {/* Define nested routes */}
      <div className='mt-[20vw] md:mt-[4.5rem]'> {/* Adjust margin for better spacing */}
        <div className="flex-1 md:px-8 px-12px-vw overflow-auto w-full h-full ">
          <Outlet /> {/* This will render the nested routes */}
        </div>
      </div> 
      <Footer/>
    </div>
  );
};

export default HomePage;
