import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  user_name: Yup.string().required("User Name is required"),
  father_name: Yup.string().required("Father's Name is required"),
  phone: Yup.string()
    .required("Phone Number is required")
    .matches(/^[0-9]+$/, "Phone Number must be digits only")
    .min(10, "Phone Number must be at least 10 digits")
    .max(10, "Phone Number must be at most 10 digits"),

  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  address_line_1: Yup.string().required("Address Line 1 is required"),
  village: Yup.string().required("Village is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  // gstin_number: Yup.string()
  //   .matches(
  //     /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([A-Z0-9]{3})$/,
  //     "Invalid GSTIN format"
  //   ),
  // dealer_price_discount: Yup.number()
  //   .typeError("Dealer Price Discount must be a number")
  //   .min(0, "Discount must be greater than or equal to 0"),
  // employee_monthly_salary: Yup.number()
  //   .typeError("Monthly Salary must be a number")
  //   .min(0, "Salary must be greater than or equal to 0")
  //   .required("Monthly Salary is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
  // role_id: Yup.string().required("Role ID is required"),
  start_login_time: Yup.string()
    .required("Start login time is required")
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Invalid time format (HH:mm)"),
  end_login_time: Yup.string()
    .required("End login time is required")
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Invalid time format (HH:mm)"),
  payment_sms_reminder: Yup.string().nullable(),
  // employee_type: Yup.string().required("Employee Type is required"),
  // overtime_per_hour_salary: Yup.number()
  //   .typeError("Overtime Salary must be a number")
  //   .min(0, "Overtime Salary must be greater than or equal to 0")
  //   .required("Overtime Salary is required"),
}); 

export const itemSchema = Yup.object().shape({
  item_category_id: Yup.string().required('Item category is required'), // Required
  item_name: Yup.string()
    .required('Item name is required')
    .min(2, 'Item name must be at least 2 characters'), // Required
  hsn: Yup.string().required('HSN is required'), // Required
  default_length_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_width_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_thickness_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_weight_kg: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_tax_percentage: Yup.number()
    .nullable() // Optional
    .min(0, 'Must be at least 0')
    .max(100, 'Must be at most 100'), // Positive if provided
  usage_type: Yup.string().required('Usage type is required'), 
  type: Yup.string().required('Item type is required'), // Required
});
  
export const editItemSchema = Yup.object().shape({
  item_category_id: Yup.string().required('Item category is required'), // Required
  item_name: Yup.string()
    .required('Item name is required')
    .min(2, 'Item name must be at least 2 characters'), // Required
  hsn: Yup.string().required('HSN is required'), // Required
  default_length_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_width_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_thickness_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_weight_kg: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_tax_percentage: Yup.number()
    .nullable() // Optional
    .min(0, 'Must be at least 0')
    .max(100, 'Must be at most 100'), // Positive if provided
  usage_type: Yup.string().required('Usage type is required'), 
  type: Yup.string().required('Item type is required'), // Required
  stock: Yup.number()
    .nullable() // Optional
    .min(0, 'Stock cannot be negative'), // Optional
  unit_price: Yup.number()
    .nullable() // Optional
    .min(0, 'Unit price cannot be negative'), // Optional
  sell_price: Yup.number()
    .nullable() // Optional
    .min(0, 'Sell price cannot be negative'), // Optional
  expected_delivery_party: Yup.string()
    .nullable() // Optional
    .min(2, 'Expected delivery party must be at least 2 characters'), // Optional
  minimum_stock_alert: Yup.number()
    .nullable() // Optional
    .min(0, 'Minimum stock alert cannot be negative'), // Optional
}); 

export const splitFormSchema = Yup.object().shape({
  item_id: Yup.string().required("Item ID is required"),
  item_name: Yup.string().required("Item name is required"),
  item_weight: Yup
    .number()
    .min(1, "Total available weight must be at least 1")
    .required("Total available weight is required"),
  sgew_price: Yup
    .number()
    .min(0, "Price cannot be negative")
    .required("Item SGEW price is required"),
  subitems: Yup.array().of(
    Yup.object().shape({
      subitem_id: Yup.string().required("Subitem ID is required"),
      length: Yup.number().required("Length is required"),
      width: Yup.number().required("Width is required"),
      thickness: Yup.number().required("Thickness is required"),
      weight: Yup.number().required("Weight is required"),
      quantity: Yup.number().required("Quantity is required"),
      sgew_price: Yup
        .number()
        .min(0, "Price cannot be negative")
        .required("SGEW price is required"),
      created_by: Yup.string().required("Creator is required"),
    })
  ),
}); 

// productSchema.js
export const productSchema = Yup.object().shape({
  product_category: Yup.string().required('Product category is required'),
  product_name: Yup.string().required('Product name is required'),
  hsn_code: Yup.string().required('HSN code is required'),
  product_sku: Yup.string().required('Product SKU is required'),
  weight: Yup.number().typeError('Weight must be a number').required('Weight is required'),
  // product_images: Yup.array().of(Yup.string().url('Each image must be a valid URL')),
  display_on_website: Yup.string().oneOf(['Enable', 'Disable'], 'Display option must be Enable or Disable'),
  item_details: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().typeError('ID must be a number').required('ID is required'),
      name: Yup.string().required('Item name is required'),
      quantity: Yup.number().typeError('Quantity must be a number').required('Quantity is required'),
      weightPerUnit: Yup.number().typeError('Weight per unit must be a number').required('Weight per unit is required'),
      sgew_price: Yup.number().typeError('Sgew price must be a number').required('Sgew price is required'),
    })
  ),
  subitems: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().typeError('ID must be a number').required('ID is required'),
      name: Yup.string().required('Subitem name is required'),
      quantity: Yup.number().typeError('Quantity must be a number').required('Quantity is required'),
      weightPerUnit: Yup.number().typeError('Weight per unit must be a number').required('Weight per unit is required'),
      sgew_price: Yup.number().typeError('Sgew price must be a number').required('Sgew price is required'),
    })
  ),
  amount: Yup.number().typeError('Amount must be a number').required('Amount is required'),
  gst_percentage: Yup.number().typeError('GST percentage must be a number').required('GST percentage is required'),
  service_duration: Yup.number().typeError('Service Duration must be number').required('GST percentage is required'),
  labour_charge: Yup.number().typeError('Labour charge must be a number').required('Labour charge is required'),
  grand_total: Yup.number().typeError('Grand total must be a number').required('Grand total is required'),
});


