import React, { useEffect, useState } from "react";
import CustomDatatable from "../../components/DataTable/CustomDatatable";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loading";
import { getReq } from "../../apis/api";
import { useOutletContext } from "react-router-dom";
export const UserTable = () => {
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  const { setFetchDataHandler } = useOutletContext();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const result = await getReq("/fetchAllUsers");
      const { status, data } = result;
      if (status === 200) {
        setUserData(data);
      } else {
        throw new Error("Failed to fetch user data");
      }
    } catch (err) {
      console.error("Failed to fetch data", err);
      toast.error("Error fetching user data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); 
     setFetchDataHandler(fetchData);
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    { name: "Type", selector: (row) => row.role_name, sortable: true },
    { name: "Name", selector: (row) => row.user_name, sortable: true },
    { name: "Phone", selector: (row) => row.phone || "N/A", sortable: true },
    { name: "Mobile", selector: (row) => row.mobile || "N/A", sortable: true },
    { name: "Email", selector: (row) => row.email, sortable: true },
    {
      name: "Address",
      selector: (row) => `${row.village}`,
      sortable: true,
    },
    // {
    //   name: "Access Level",
    //   selector: (row) => row.role_id,
    //   sortable: true,
    //   center: true,
    //   wrap: true,
    //   minWidth: "200px",
    // },
    {
      name: "Amount",
      selector: (row) => row.outstanding_balance_amount || "N/A",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.status === "Enable"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.status === "Disable"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.status || "N/A"}
        </div>
      ),
      sortable: true,
    },
  ];
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomDatatable
          columns={columns}
          data={userData}
          fetchUsers={fetchData}
        />
      )}
    </div>
  );
};

export default UserTable;
