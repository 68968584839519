// import React, { useEffect, useState } from "react";
// import BreadCrumbs from "../../components/common/BreadCrumbs";
// import Button from "../../components/Button";
// import AddSubItemModal from "../../components/Modal/subitems/Create";
// import { getReq } from "../../apis/api";

// const AllSubItems = () => {
//   const [openAddModal, setOpenAddModal] = useState(false);
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const res = await getReq('/item-categories'); // Make the API request
//         console.log(res,"asdfghjkl");
//         setCategories(res.data|| []); // Assuming res.data contains the category data
//       } catch (e) {
//         console.error("Error fetching categories:", e);
//       }
//     };

//     fetchCategories();
//   }, []);

//   const handleOpenModal = () => {
//     setOpenAddModal(true);
//   };

//   const handleCloseModal = () => {
//     setOpenAddModal(false);
//   };

//   return (
//     <div>
//       <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
//         <BreadCrumbs title={"Home  /  Subitems "} />
//         <Button title="Add SubItems" func={handleOpenModal} />
//       </div>
//       <div>
//         { openAddModal &&
//        <AddSubItemModal
//       open={openAddModal}
//       handleClose={handleCloseModal}
//       categories={categories} // Passing fetched categories
// />
//         }

//           <div> {/* Placeholder for the table */} table </div>

//       </div>
//     </div>
//   );
// };

// export default AllSubItems;
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import Button from "../../components/Button";
import AddSubItemModal from "../../components/Modal/subitems/Create";
import { getReq } from "../../apis/api";
import AllSubItemsTable from "../../components/DataTable/AllSubItemsTable"; // Assuming you have or will create this component
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loading";

const AllSubItems = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subItemsData, setSubItemsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
  };

  const fetchSubItemsData = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/subitems"); // API call for fetching subitems data
      console.log(response, "tableee");
      if (response.status === 200) {
        setSubItemsData(response.data);
      }
    } catch (error) {
      console.error("Error fetching subitems data:", error);
      toast.error("Error in fetching subitems data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await getReq("/item-categories");
      setCategories(res.data || []); // Assuming res.data contains the category data
    } catch (e) {
      console.error("Error fetching categories:", e);
      toast.error("Error in fetching categories");
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchSubItemsData();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "5.21vw", // Converted from 100px
      center: true,
    },
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "5.21vw", // Converted from 100px
    },
    {
      name: "Name",
      selector: (row) => row.subitem_name,
      sortable: true,
    }, 
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "SubItem Code",
      selector: (row) => row.subitem_code,
      sortable: true,
      width: "7.81vw", // Converted from 150px
    },
    // {
    //   name: "Category",
    //   selector: (row) => row.item_category_id,
    //   sortable: true,
    // },
    {
      name: "HSN Code",
      selector: (row) => row.hsn_code,
      sortable: true,
      width: "7.81vw", // Converted from 150px
    },
    {
      name: "Processing Fees",
      selector: (row) => row.processing_fees,
      sortable: true,
      width: "7.81vw", // Converted from 150px
    },
    {
      name: "SGEW Price",
      selector: (row) => row.sgew_price || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "10.42vw", // Converted from 200px
    },
    {
      name: "Length(mm)",
      selector: (row) => row.default_length_mm || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "10.42vw", // Converted from 200px
    },
    {
      name: "Width(mm)",
      selector: (row) => row.default_width_mm || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "10.42vw", // Converted from 200px
    },
    {
      name: "Thickness (mm)",
      selector: (row) => row.default_thickness_mm || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "10.42vw", // Converted from 200px
    },
    {
      name: "Default Weight (kg)",
      selector: (row) => row.default_weight_kg || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "10.42vw", // Converted from 200px
    },
    {
      name: "Scrap Weight (kg)",
      selector: (row) => row.scrap_weight_kg || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "10.42vw", // Converted from 200px
    },
    {
      name: "Total Weight (kg)",
      selector: (row) => row.total_weight_kg || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "10.42vw", // Converted from 200px
    },
    {
      name: "Sell Price",
      selector: (row) => row.sell_price || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "7.81vw", // Converted from 150px
    },
    {
      name: "Stock Alert",
      selector: (row) => row.min_stock_alert || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "7.81vw", // Converted from 150px
    },
    {
      name: "Status",
      selector: (row) => row.status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw] ${
            row.status === "Enable"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.status === "Disable"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.status || "N/A"}
        </div>
      ),
      sortable: true,
    },
  ];

  const onSubItemAdded = () => {
    fetchSubItemsData(); // Re-fetch subitems when a new subitem is added
  };

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Subitems "} />
        <Button title="Add SubItem" func={handleOpenModal} />
      </div>

      <main>
        {isLoading ? (
          <Loader />
        ) : (
          <AllSubItemsTable
            columns={columns}
            data={subItemsData}
            categories={categories}
            onItemAdded={onSubItemAdded}
          />
        )}
      </main>

      {openAddModal && (
        <AddSubItemModal
          open={openAddModal}
          handleClose={handleCloseModal}
          categories={categories}
          onSubItemAdded={onSubItemAdded}
        />
      )}
    </div>
  );
};

export default AllSubItems;
