import React, { useState, useEffect } from "react";
import axios from "axios";
import { getReq, postReq } from "../../../apis/api";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "../../../utils/validation";
import { toast, ToastContainer } from "react-toastify";
import {
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../Button";
import {
  AccessTime,
  AccountBalance,
  AccountCircle,
  AttachMoney,
  CheckCircle,
  Email,
  Group,
  Home,
  LocationCity,
  LocationOn,
  Lock,
  MonetizationOn,
  Notifications,
  Person,
  Phone,
  PhoneAndroid,
  Public,
  VerifiedUser,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
// Custom styled component for Box with specific styles
const CustomBox = styled(Box)(({ theme }) => ({
  fontFamily: "Poppins, sans-serif",
  fontSize: "12px",
  padding: "16px",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0)",
  backgroundColor: "#fff",
}));

// Custom theme with Poppins font
const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 12,
  },
});

export const CreateUser = ({ open, handleClose, onUserCreated }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      user_name: "",
      father_name: "",
      phone: "",
      mobile: "",
      email: "",
      address_line_1: "",
      address_line_2: "",
      village: "",
      district: "",
      state: "Madhya Pradesh",
      country: "India",
      bank_account_number: "",
      bank_acc_holder_name: "",
      IFSC: "",
      gstin_number: "",
      dealer_price_discount: "",
      employee_monthly_salary: "",
      password: "",
      role_id: "",
      start_login_time: "09:00",
      end_login_time: "17:00",
      payment_sms_reminder: "Yes",
      status: "Enable",
      employee_type: "Permanent",
      overtime_per_hour_salary: "",
    },
  });

  const [roles, setRoles] = useState([]);
  const [step, setStep] = useState(0);
  const [errMsg, setErrMsg] = useState(null);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Fetch roles from the API
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getReq("/fetchrole");

        console.log("Fetched Roles:", response);

        const filteredRoles = response.data.filter((role) => role.id !== "1");
        setRoles(filteredRoles);
      } catch (error) {
        console.error("Error fetching roles:", error);
        setError("Failed to fetch roles. Please try again later.");
      }
    };

    fetchRoles();
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const roleId = watch("role_id");

  // Move to next step
  const handleNext = () => {
    if (!roleId) {
      toast.error("Please select a role.");
      return;
    }
    setStep(1);
  };

  // Go back to previous step
  const handleBack = () => {
    setStep(0);
  };

  // Handles form submission
  const onSubmit = async (data) => {
    console.log("data", data);
    try {
      const response = await postReq(data, "/signup");
      console.log("Response:", response.data);

      // Close the modal on success
      if (response.status === 200) {
        toast.success("User created successfully!");

        // Delay before closing the modal and updating the table
        setTimeout(() => {
          handleClose(); // Close the modal
          onUserCreated(); // Refresh the table
        }, 1000);
      } else {
        // Show error message for non-200 responses
        toast.error(response.data.message || "User Not Created", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      // Handling errors and displaying the appropriate error message
      if (error.response && error.response.data && error.response.data.msg) {
        const errorMsg = error.response.data.msg;

        // Display error message in toast
        Object.values(errorMsg).forEach((msg) => {
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      } else {
        setErrMsg("Something went wrong. Please try again.");
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              fontSize: "1vw", // Input text size using vw
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "1vw", // Placeholder text size using vw
            },
            "& .MuiInputLabel-root": {
              fontSize: "1vw", // Label text size using vw
            },
            "& .MuiInputAdornment-root svg": {
              fontSize: "1.2vw", // Icon size using vw
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "& .MuiSelect-select": {
              fontSize: "1vw", // Select text size using vw
            },
            "& .MuiInputLabel-root": {
              fontSize: "1vw", // Label text size using vw
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        maxWidth="xl"
        className="w-full"
        PaperProps={{
          sx: {
            borderRadius: "5px", // This removes the rounded corners
          },
        }}
      >
        <form  onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle className="border-b border-gray-300 ">
            Create User
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent
            fullWidth
            className="min-w-[45vw] md:mt-20px-vw max-w-[80vw]"
            sx={{
              paddingX: "30px", // Increased padding X
              paddingY: "30px", // Increased padding Y
            }}
          >
            <div component="div">
              <div className="pb-[5vh]">
                <Stepper activeStep={step}>
                  <Step>
                    <StepLabel>Select Role</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Enter User Details</StepLabel>
                  </Step>
                </Stepper>
              </div>

              {step === 0 ? (
                <Grid item xs={12}>
                  <FormControl fullWidth required variant="outlined">
                    <Controller
                      name="role_id"
                      control={control}
                      defaultValue="" // Default value for role_id
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          label="Role" // Floating label
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle className="md:mb-2 text-center" />
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: "1.2rem", // Change font size for the label
                            },
                          }}
                          sx={{
                            "& .MuiInputBase-input": {
                              fontSize: "1.2rem", // Change font size for the input text
                            },
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                sx: {
                                  maxHeight: 250, // Adjust the max height of the dropdown
                                  "& .MuiMenuItem-root": {
                                    typography: "body2", // Adjust text size
                                    fontSize: "1.2rem", // Change font size
                                  },
                                },
                              },
                            },
                          }}
                          error={!!errors.role_id} // Validation error
                          helperText={errors.role_id?.message} // Error message
                        >
                          <MenuItem value="" disabled>
                            Select a role
                          </MenuItem>
                          {roles.map((role) => (
                            <MenuItem key={role.id} value={role.id}>
                              {role.role_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </FormControl>
                </Grid>
              ) : (
                <ThemeProvider theme={theme}>
                  <Grid container spacing={3.5}>
                  
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="user_name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="User Name"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccountCircle />
                                </InputAdornment>
                              ),
                              placeholder: "Enter your username",
                            }}
                            error={!!errors.user_name}
                            helperText={errors.user_name?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="father_name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Father's Name"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Person />
                                </InputAdornment>
                              ),
                              placeholder: "Enter father's name",
                            }}
                            error={!!errors.father_name}
                            helperText={errors.father_name?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Phone Number"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Phone />
                                </InputAdornment>
                              ),
                              placeholder: "Enter phone number",
                            }}
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="mobile"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Mobile Number"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PhoneAndroid />
                                </InputAdornment>
                              ),
                              placeholder: "Enter mobile number",
                            }}
                            error={!!errors.mobile}
                            helperText={errors.mobile?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Email"
                            type="email"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Email />
                                </InputAdornment>
                              ),
                              placeholder: "Enter email here",
                            }}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                          />
                        )}
                      />
                    </Grid>

                    {/* Address Line 1 */}
                    <Grid item xs={12} sm={6} md={4.5}>
                      <Controller
                        name="address_line_1"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Address Line 1"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Home />
                                </InputAdornment>
                              ),
                              placeholder: "Enter address line 1",
                            }}
                            error={!!errors.address_line_1}
                            helperText={errors.address_line_1?.message}
                          />
                        )}
                      />
                    </Grid>

                    {/* Address Line 2 (Optional) */}
                    <Grid item xs={12} sm={6} md={4.5}>
                      <Controller
                        name="address_line_2"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Address Line 2"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Home />
                                </InputAdornment>
                              ),
                              placeholder: "Enter address line 2",
                            }}
                            error={!!errors.address_line_2}
                            helperText={errors.address_line_2?.message}
                          />
                        )}
                      />
                    </Grid>

                    {/* Village */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="village"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Village"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LocationCity />
                                </InputAdornment>
                              ),
                              placeholder: "Enter village name",
                            }}
                            error={!!errors.village}
                            helperText={errors.village?.message}
                          />
                        )}
                      />
                    </Grid>

                    {/* District */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="district"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="District"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LocationCity />
                                </InputAdornment>
                              ),
                              placeholder: "Enter district name",
                            }}
                            error={!!errors.district}
                            helperText={errors.district?.message}
                          />
                        )}
                      />
                    </Grid>

                    {/* State */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="State"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LocationOn />
                                </InputAdornment>
                              ),
                            }}
                            error={!!errors.state}
                            helperText={errors.state?.message}
                          />
                        )}
                      />
                    </Grid>

                    {/* Country */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Country"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Public />
                                </InputAdornment>
                              ),
                              placeholder: "Enter country name",
                            }}
                            error={!!errors.country}
                            helperText={errors.country?.message}
                          />
                        )}
                      />
                    </Grid>

                    {/* Bank Details */}
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="bank_account_number"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Bank Account Number"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccountBalance />
                                </InputAdornment>
                              ),
                              placeholder: "Enter bank account number",
                            }}
                            error={!!errors.bank_account_number}
                            helperText={errors.bank_account_number?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="bank_acc_holder_name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Account Holder Name"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Person />
                                </InputAdornment>
                              ),
                              placeholder: "Enter account holder's name",
                            }}
                            error={!!errors.bank_acc_holder_name}
                            helperText={errors.bank_acc_holder_name?.message}
                          />
                        )}
                      />
                    </Grid>

                    {(roleId === "7" || roleId === "8" || roleId === "9") && (
                      <Grid item xs={12} sm={6} md={3}>
                        <Controller
                          name="gstin_number"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="GSTIN Number"
                              variant="outlined"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MonetizationOn />
                                  </InputAdornment>
                                ),
                                placeholder: "Enter GSTIN number",
                              }}
                              error={!!errors.gstin_number}
                              helperText={errors.gstin_number?.message}
                            />
                          )}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="IFSC"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="IFSC"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <VerifiedUser />
                                </InputAdornment>
                              ),
                              placeholder: "Enter IFSC code",
                            }}
                            error={!!errors.IFSC}
                            helperText={errors.IFSC?.message}
                          />
                        )}
                      />
                    </Grid>

                    {/* Dealer Price Discount Field */}
                    {roleId === "8" && (
                      <Grid item xs={12} sm={6} md={3}>
                        <Controller
                          name="dealer_price_discount"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="Dealer Price Discount (0-100)"
                              type="number"
                              variant="outlined"
                              InputProps={{
                                placeholder: "Enter discount percentage",
                              }}
                              error={!!errors.dealer_price_discount}
                              helperText={errors.dealer_price_discount?.message}
                            />
                          )}
                        />
                      </Grid>
                    )}

                    {/* Password Field */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Lock />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              placeholder: "Enter your password",
                            }}
                            error={!!errors.password}
                            helperText={errors.password?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="payment_sms_reminder"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            select
                            label="Payment SMS Reminder"
                            variant="outlined"
                            fullWidth
                            error={!!errors.payment_sms_reminder}
                            helperText={errors.payment_sms_reminder?.message}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>

                    {roleId === "10" && (
                      <Grid item xs={12} sm={6} md={3}>
                        <Controller
                          name="employee_type"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              select
                              label="Employee Type" // Ensure label is set
                              variant="outlined"
                              fullWidth
                              error={!!errors.employee_type}
                              helperText={errors.employee_type?.message}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Group /> {/* Icon representing employee */}
                                  </InputAdornment>
                                ),
                              }}
                            >
                              <MenuItem value="">Select Employee Type</MenuItem>{" "}
                              {/* Placeholder option */}
                              <MenuItem value="Permanent">Permanent</MenuItem>
                              <MenuItem value="Vendor">Vendor</MenuItem>
                            </TextField>
                          )}
                        />
                      </Grid>
                    )}

                    {roleId === "10" && (
                      <Grid item xs={12} sm={6} md={3}>
                        <Controller
                          name="employee_monthly_salary"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="Employee Monthly Salary"
                              type="text"
                              variant="outlined"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AttachMoney />
                                  </InputAdornment>
                                ),
                                placeholder: "Enter monthly salary",
                              }}
                              error={!!errors.employee_monthly_salary}
                              helperText={
                                errors.employee_monthly_salary?.message
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}

                    {roleId === "10" &&
                      watch("employee_type") === "Permanent" && (
                        <Grid item xs={12} sm={6} md={3}>
                          <Controller
                            name="overtime_per_hour_salary"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Overtime Per Hour Salary"
                                type="text"
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AttachMoney />
                                    </InputAdornment>
                                  ),
                                  placeholder: "Enter Overtime Per Hour salary",
                                }}
                                error={!!errors.overtime_per_hour_salary}
                                helperText={
                                  errors.overtime_per_hour_salary?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}

                    {/* Start Login Time Field */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="start_login_time"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Start Login Time"
                            type="time"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              step: 300, // 5-minute intervals
                              placeholder: "HH:MM",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccessTime />
                                </InputAdornment>
                              ),
                            }}
                            error={!!errors.start_login_time}
                            helperText={errors.start_login_time?.message}
                          />
                        )}
                      />
                    </Grid>

                    {/* End Login Time Field */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Controller
                        name="end_login_time"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="End Login Time"
                            type="time"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              step: 300, // 5-minute intervals
                              placeholder: "HH:MM",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccessTime />
                                </InputAdornment>
                              ),
                            }}
                            error={!!errors.end_login_time}
                            helperText={errors.end_login_time?.message}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </ThemeProvider>
              )}
            </div>
          </DialogContent>
          <DialogActions className=" md:mr-14px-vw md:mb-10px-vw ">
            {step === 1 && (
              <Button
                type="button"
                func={handleBack}
                title={"Back"}
                customcls="bg-[#d0412e33]  text-[#D0412E] border border-[#d0412e33]"
              />
            )}
            {step === 0 ? (
              <Button type="button" func={handleNext} title={"Next"} />
            ) : (
              <button 
                type="submit"
                className="flex items-center text-18px-vw justify-center px-4 py-[0.5vw] font-semibold rounded-[0.24vw] transition duration-300 ease-in-out transform focus:outline-none bg-[#0074ff] hover:bg-blue-700 text-white"
              >
                Save
              </button>
            )}
          </DialogActions>
        </form>
      </Dialog>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default CreateUser;
