
import { Navigate } from "react-router-dom"; 
import Cookies from "js-cookie"; 

function OpenRoute({ element }) {
const token = Cookies.get("token"); 

  if (!token) {
    return element; 
  } else {
    return <Navigate to="/" />;
  }
}

export default OpenRoute;
